import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { User } from '../models/user';
import { Helpers } from '../helpers';
import { AlertHelper } from '../helpers/alert-helper';
import { MatDialog } from '@angular/material';
import { AuthService } from '../network/_services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  currentYear: string = new Date().getFullYear().toString();
  user_login = {email: "", password: ""};
  frm_err: boolean = false;
  frm_err_text = "";
  show_async_loader = false;
  alertHelper: AlertHelper
  constructor(private auth: AuthService, private router: Router, dialog: MatDialog) {
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
    if (localStorage.getItem(Helpers.DS_INT_TIMEOUT)  === 'true') {
      localStorage.removeItem(Helpers.DS_INT_TIMEOUT);
      this.alertHelper.showOkAlert('Session Timeout', 'Your session has expired, Please log in again');
    }
  }

  login(){
    // var user = new User();
    // User.updateCurrentUser(user);
    // this.router.navigate(['/dashboard']);
    this.frm_err = false;
    if(this.user_login.email.trim() == ""){
      this.frm_err = true;
      this.frm_err_text = "Please enter your email address."
    }else if(this.user_login.password.trim() == ""){
      this.frm_err = true;
      this.frm_err_text = "Please enter your password."
    }
    if(!this.frm_err){
      this.show_async_loader = true;
      this.auth.login(this.user_login).subscribe((result) =>{
        if(!result['error']){
          // console.log(JSON.parse(result['response']));
          // const response = JSON.parse(result['response']);
          localStorage.setItem(Helpers.STORAGE_TAG, result['response']);
          this.router.navigate(['/']);
          // if(!response.PasswordChanged){
          //   this.router.navigate['/change-password'];
          //   this.alertHelper.showOkAlert('Info', 'This is your first login, please change your password');
          // } else {
          // }
        }else{
          this.show_async_loader = false;
          var token_email = null;
          if(result['response'] == Helpers.ERR_VERIFY_EMAIL){
            token_email = {email: this.user_login.email, tokenExpired: false};
          }else if(result['response'] == Helpers.ERR_TOKEN_EXPIRED){
            token_email = {email: this.user_login.email, tokenExpired: true};
          }else{
            this.alertHelper.showOkAlert("Login Error", result['response']);
          }
          if(token_email){
            localStorage.setItem(Helpers.VERIFY_EMAIL_DETAIL, JSON.stringify(token_email));
            this.router.navigate(['/verify_email']);
          }
        }
      }, (error) =>{
        this.show_async_loader = false;
        this.alertHelper.showErrorAlert();
      });
    }else{
      this.alertHelper.showOkAlert("Login Error", this.frm_err_text);
    }
  }

  passwordKeyPress(event: KeyboardEvent){
    if(event.keyCode == 13){
      this.login();
    }
  }
}
