import { InternalDocumentComponent } from './internal-document/internal-document.component';
import { MemosComponent } from './memos/memos.component';
import { ChatpageComponent } from './chatpage/chatpage.component';
import { TreatFileComponent } from './treat-file/treat-file.component';
import { RewardsComponent } from './rewards/rewards.component';
import { ProfileComponent } from './profile/profile.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SignUpDoneComponent } from './sign-up-done/sign-up-done.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { GuardService } from './network/_guards/auth.guard';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ViewArchiveComponent } from './view-archive/view-archive.component';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { LeaveMgtComponent } from './leave-mgt/leave-mgt.component';
import { ViewLeaveComponent } from './view-leave/view-leave.component';
import { InterMinistryModule } from './inter-ministry/inter-ministry.module';


const routes: Routes = [
  {path: 'login', canActivate: [GuardService], component: SignInComponent},
  {path: 'register', canActivate: [GuardService], component: SignUpComponent},
  {path: 'register/done', canActivate: [GuardService], component: SignUpDoneComponent},
  {path: 'verify_email', canActivate: [GuardService], component: VerifyEmailComponent},
  {path: 'reset-password', canActivate: [GuardService], component: ResetPasswordComponent},
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {
      path: '',
      canActivate: [GuardService],
      component: DashboardLayoutComponent,
      children: [
        { path: 'dashboard', canActivate: [GuardService], component: DashboardComponent,  data:{title: 'Dashboard'}},
        { path: 'rewards', canActivate: [GuardService], component: RewardsComponent,  data:{title: 'Rewards'}},
        { path: 'treat-file/:ref', canActivate: [GuardService], component: TreatFileComponent,  data:{title: 'Treat File'}},
        { path: 'document/:type/:ref', canActivate: [GuardService], component: InternalDocumentComponent,  data:{title: 'Document'}},
        { path: 'account', canActivate: [GuardService], component: ProfileComponent,  data:{title: 'Account'}},
        { path: 'change-password', canActivate: [GuardService], component: ChangePasswordComponent,  data:{title: 'Change Password'}},
        { path: 'chat', canActivate: [GuardService], component: ChatpageComponent,  data:{title: 'Chat'}},
        { path: 'file-manager', canActivate: [GuardService], component: FileManagerComponent,  data:{title: 'File Manager'}},
        { path: 'documents', canActivate: [GuardService], component: MemosComponent,  data:{title: 'Memos'}},
        {path: 'view-archive/:FilesTrackingNo', canActivate: [GuardService], component: ViewArchiveComponent},
        {path: 'view-leave/:LeaveRefNumber', canActivate: [GuardService], component: ViewLeaveComponent},
        { path: 'leave-mgt', canActivate: [GuardService], component: LeaveMgtComponent,  data:{title: 'Leave'}},
        { path: 'inter-ministry', loadChildren: './inter-ministry/inter-ministry.module#InterMinistryModule' }
      ]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
