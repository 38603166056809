import {NgModule, ModuleWithProviders} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPrintModule } from 'ngx-print';
import { MatDialogModule, MatProgressSpinnerModule, MatPaginatorModule } from '@angular/material';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { TabsModule, TabsetConfig } from 'ngx-bootstrap/tabs/';
import { GridAllModule, PagerModule } from '@syncfusion/ej2-angular-grids';


@NgModule({
    imports: [
        MatDialogModule,
        MatProgressSpinnerModule,
        SelectDropDownModule,
        NgxPrintModule,
        MultiSelectModule,
        CommonModule,
        MultiSelectModule,
        TabsModule,
        GridAllModule,
        PagerModule,
        MatPaginatorModule
    ],
    declarations: [
        AlertDialogComponent
    ],
    exports: [
        CommonModule, MatDialogModule, MatProgressSpinnerModule, SelectDropDownModule, NgxPrintModule, MultiSelectModule,
        AlertDialogComponent,
        MultiSelectModule,
        TabsModule,
        GridAllModule,
        PagerModule,
        MatPaginatorModule
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders {
        return {
          ngModule: SharedModule,
          providers: [ TabsetConfig ]
        };
      }
}