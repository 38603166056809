import { Helpers } from './../helpers';
import { Role } from "./role";

export class User {
    uid?: number;
    email: string = "";
    FirstName: string = "";
    LastName: string = "";
    Position?: string = "";
    UserColour: string = "#E6BC38";
    MinistryName: string = "";
    DepartmentName = '';
    role: Role;
    phone: string = "";
    address: string = "";
    gender: string = "Male";
    photo?: string;
    about?: string = "";
    token?: string;
    state?: string = "";
    website?: string;
    password?: string = "";
    cpass?: string = "";

    static getCurrentUser() {
        var user: User = JSON.parse(localStorage.getItem(Helpers.STORAGE_TAG));
        return user
    }

    static updateCurrentUser(user: User, keepToken?:boolean){
        if(keepToken){
            var _user: User = this.getCurrentUser();
            user.token = _user.token;
        }
        localStorage.setItem(Helpers.STORAGE_TAG, JSON.stringify(user));
    }

    getObject(){
        return JSON.parse(JSON.stringify(this));
    }
}