import { User } from 'src/app/models/user';
import { MatDialog } from '@angular/material';
import { AlertHelper } from './../helpers/alert-helper';
import { DashboardLayoutComponent } from './../dashboard-layout/dashboard-layout.component';
import { NetworkService } from './../network/_services/network';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SubmissionComment, TreatmentStatus } from '../treat-file/treat-file.component';
import moment from 'moment';

@Component({
  selector: 'app-internal-document',
  templateUrl: './internal-document.component.html',
  styleUrls: ['./internal-document.component.scss']
})
export class InternalDocumentComponent implements OnInit {

  loggedUser: User;
  parent: DashboardLayoutComponent;
  document_ref: string = "";
  document_type: string = "";
  document_info: DocumentInfo = new DocumentInfo();


  files_async: boolean = false;
  comments_async: boolean = false;
  showRetryOnError: boolean = false;
  errorPage: boolean = false;
  errorPage_text: string = "";

  route_dialog= {route: false, routeTitle:  "", routeType: "", routeAsync: false};
  route_message: string = "";

  toggle_users_dropdown: boolean = true;
  selected_route_sub_user: {id: string, name: string, level: number, position: string};
  sel_department: {id: string, name: string};

  config = {
    displayKey:"name", //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: 10, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  reject_submission: boolean = false;
  reject_submission_err: boolean = false;
  reject_submission_err_text: string = "";

  approve_disapprove: {title: string, async: boolean, error: boolean, errText: string, show: boolean} = {title: "", async: false, error: false, errText: "", show: false};
  approve_disapprove_message: string = "";

  departments_load_async: boolean = false;
  department_users_load_async: boolean = false;

  submission_departments: {id: string, name: string}[] = new Array();
  department_users: {id: string, name: string, level: number, position: string}[] = new Array();

  alertHelper: AlertHelper;

  doc_types: Map<string, number> = new Map();

  recipientsSearchValue: string = "";
  mrecipients: Array<{name: string, color: string, fname: string, lname: string, email: string, position: string}> = new Array();

  constructor(private route: ActivatedRoute, private router: Router, private network: NetworkService, private dialog: MatDialog, private cd: ChangeDetectorRef) {
    this.doc_types.set("memo", InternalDocumentType.Memo);
    this.doc_types.set("minute", InternalDocumentType.Minute);
    this.doc_types.set("circular", InternalDocumentType.Circular);
    this.route.params.subscribe((params) => {
      //console.log(params)
      this.document_ref = params['ref'];
      this.document_type = params['type'];
    })
    
    if(!this.document_ref || !this.document_type || this.doc_types.get(this.document_type) == null){
      this.router.navigate(['/']);
    }
    this.alertHelper = new AlertHelper(dialog);
    this.loggedUser = User.getCurrentUser();
    
   }

  ngOnInit() {
    this.loadDocumentDetails();
  }

  loadDocumentDetails(){
    this.files_async = true;
    this.comments_async = true;
    this.showRetryOnError = false;
    this.errorPage = false;
    this.network.sendRequest({refNo: this.document_ref, type: this.doc_types.get(this.document_type)}, NetworkService.GET_DOC_DETAILS).subscribe((res)=>{
      this.files_async = false
      this.comments_async = false;
      if(!res['error']){
        this.document_info = JSON.parse(res['response']);
        if(this.document_type == 'minute'){
          this.mrecipients = this.document_info.mrecipients;
        }
      }else{
        this.errorPage_text = res['response'];
        this.errorPage = true;
      }
    }, (error)=>{
      this.files_async = false
      this.comments_async = true;
      this.showRetryOnError = true;
      this.errorPage_text = "Sorry, an unexpected error occured.";
      this.errorPage = true;
    })
  }

  loadDepartments(){
    this.refreshDepartmentUsers();
    var _team = "0"
    if(this.submission_departments.length <= 0){
      this.departments_load_async = true;
      this.network.sendRequest({ref: this.document_ref, team: _team}, NetworkService.GET_DEPARTMENTS).subscribe((res)=>{
        this.departments_load_async = false
        if(!res['error']){
          var deps = JSON.parse(res['response']);
          this.submission_departments = deps;
          
        }
      }, (error)=>{
        this.departments_load_async = false;
      })
    }
  }
  refreshDepartmentUsers(){
    this.toggle_users_dropdown = false;
    this.selected_route_sub_user = null;
    this.department_users = new Array();
    setTimeout(() => {
      this.toggle_users_dropdown = true;
    }, 1);
  }

  departmentsChange(event){
    this.refreshDepartmentUsers();
    this.sel_department = event.value;
    if(event.value){
      this.department_users_load_async = true;
      this.network.sendRequest({ref: this.document_ref, did: event.value.id}, NetworkService.GET_DEPARTMENT_USERS_PLAIN).subscribe((res) => {
        this.department_users_load_async = false
        if(!res['error']){
          var usrs = JSON.parse(res['response']);
          this.department_users = usrs;
        }
      }, (error) => {
        this.department_users_load_async = false
      });
    }
  }

  openApproveDocument(){
    this.approve_disapprove = {title: "Approve", async: false, error: false, errText: "", show: true};
  }
  openDisApproveDocument(){
    this.approve_disapprove = {title: "Disapprove", async: false, error: false, errText: "", show: true};
  }

  confirmApproveDisapprove(){
    this.alertHelper.showYesNoAlert("Confirm", "Are you sure you want to <b>" + this.approve_disapprove.title + "</b> this document. <br/> This action cannot be undone.", () => {this.approveDisapproveDocument()})
  }

  approveDisapproveDocument(){
    var isApproved = false
    if(this.approve_disapprove.title == "Approve"){
      isApproved = true;
    }else if(this.approve_disapprove.title == "Disapprove"){
      isApproved = false;
    }else{
      return;
    }
    var url = "";
    var data = {};
    switch (this.document_type) {
      case 'memo':
        data['MemoRefNo'] = this.document_ref;
        data['IsApproved'] = isApproved;
        data['Message'] = this.approve_disapprove_message;
        url = NetworkService.MEMO_TREAT;
        break;
      case 'circular':
        data['RefNo'] = this.document_ref;
        data['IsApproved'] = isApproved;
        data['Message'] = this.approve_disapprove_message;
        url = NetworkService.CIRCULAR_TREAT;
        break;
      default:
        return;
    }
    this.approve_disapprove.async = true;
    this.network.sendRequest(data, url).subscribe((res)=>{
      this.approve_disapprove.async = false;
      if(!res['error']){
        this.approve_disapprove.show = false;
        this.approve_disapprove_message = "";
        this.alertHelper.showOkAlert("Success", res['response']);
      }else{
        this.alertHelper.showOkAlert("Error", res['response']);
      }
    }, (error)=>{
      this.approve_disapprove.async = false;
      this.alertHelper.showErrorAlert();
    })
  }

  submitRoute(){
    if(this.sel_department == null){
      this.alertHelper.showOkAlert("Error", "Please select user's department")
    }else if(this.selected_route_sub_user == null){
      this.alertHelper.showOkAlert("Error", "Please select a user")
    }else if(this.route_message.trim() == ""){
      this.alertHelper.showOkAlert("Error", "Please enter a message for user")
    }else{
      var url: string = "";
      var data = {};
      data['MessageBody'] = this.route_message;
      switch (this.document_type) {
        case 'memo':
          data['MemoRefNo'] = this.document_ref;
          data['RoutedTo'] = this.selected_route_sub_user.id;
          data['Comment'] = this.route_message;
          url = NetworkService.ROUTE_MEMO;
          break;
        case 'minute':
          data['MinuteRefNo'] = this.document_ref;
          data['RouteeId'] = this.selected_route_sub_user.id;
          data['MessageBody'] = this.route_message;
          url = NetworkService.ROUTE_MINUTE;
          break;
        case 'circular':
          data['RefNo'] = this.document_ref;
          data['To'] = this.selected_route_sub_user.id;
          data['Message'] = this.route_message;
          url = NetworkService.ROUTE_CIRCULAR;
          break;
        default:
          return;
      }
      this.route_dialog.routeAsync = true;
      this.network.sendRequest(data, url).subscribe((res)=>{
        this.route_dialog.routeAsync = false;
        if(!res['error']){
          this.route_dialog.route = false;
          var comment: SubmissionComment = new SubmissionComment();
          comment.sender = this.loggedUser.FirstName + " " + this.loggedUser.LastName + " (" + this.loggedUser.Position + ")";
          comment.recipient = this.selected_route_sub_user.name;
          var cdate = moment().toString();
          comment.comment_date = cdate
          comment.comment = this.route_message;
          comment.sender_color = this.loggedUser.UserColour;
          this.route_message = "";
          this.document_info.comments.unshift(comment);
          this.alertHelper.showOkAlert("Success", res['response']);
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.route_dialog.routeAsync = false;
        this.alertHelper.showErrorAlert();
      })
    }
    
  }

  docLoaded(event){

  }

  routeDocument(){
    this.route_message = "";
    this.route_dialog = {route: true, routeTitle: "Route this document", routeType: "sub", routeAsync: false}
    this.loadDepartments();
  }

  getTreatmentStatus(status: TreatmentStatus){
    switch (status) {
      case TreatmentStatus.Pending:
        return "Pending";
        break;
      case TreatmentStatus.Rejected:
        return "Disapproved";
        break;
      case TreatmentStatus.Treated:
        return "Approved";
        break;
      case TreatmentStatus.Untreated:
        return "Untreated";
        break;
      default:
        break;
    }
  }

  searchRecipients(){
    if(this.recipientsSearchValue.trim() != ""){
      this.mrecipients = this.document_info.mrecipients.filter((c) =>{
        return c.fname.toLowerCase().includes(this.recipientsSearchValue.toLowerCase()) || c.lname.toLowerCase().includes(this.recipientsSearchValue.toLowerCase());
      });
    }else{
      this.mrecipients = this.document_info.mrecipients;
    }
  }

  clearSearchRecipients(){
    this.recipientsSearchValue = "";
    this.mrecipients = this.document_info.mrecipients;
  }
}

class DocumentInfo{
  ref: string = ""; 
  recipients: string = ""; 
  sdate: string = ""; 
  sender_email: string = "";
  published: boolean = false;
  subject: string = ""; 
  viewerType: InternalDocViewerType = null; 
  file: {fid: string, file_name: string, url: string};
  fileurl: string = "";
  comments: Array<SubmissionComment> = new Array();
  level: number;
  initiator: string = "";
  recipient: string = "";
  status: number;
  treatment_status: number;
  finalMessage: string = "";
  content: string = "";
  approvedBy: string = "";
  mrecipients?: Array<{name: string, color: string, fname: string, lname: string, email: string, position: string}> = new Array();
}
export enum InternalDocumentType{
  Memo = 1,
  Minute = 2,
  Circular = 3
}
enum InternalDocViewerType{
  Owner = "Owner",
  Routee = "Routee"
}