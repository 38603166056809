import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AlertHelper } from './../helpers/alert-helper';
import { AuthService } from './../network/_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Utils } from '../helpers/utils';
import { isNumber } from 'util';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  currentYear: string = new Date().getFullYear().toString();
  emailAddress: string = "";
  resetPasswordPage: number = 0;
  token: string = "";
  page_loader: boolean = false;
  sentToEmail: string = "";
  alertHelper: AlertHelper;
  altEmail: string = "";
  passwords= {password: "", cpass: ""};
  pass_reset_process: boolean = true;


  constructor(private auth: AuthService, private dialog: MatDialog, private router: Router) { 
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
  }

  lostEmail(){
    if(this.emailAddress.trim() == ""){
      this.alertHelper.showOkAlert("Email required", "You must enter the email which you no longer have access to.");
    }else if(!Utils.isValidEmail(this.emailAddress)){
      this.alertHelper.showOkAlert("Invalid Email", "Please enter a valid email address");
    }else{
      this.page_loader = true;
      this.auth.altEmail({email: this.emailAddress}).subscribe((res)=>{
        this.page_loader = false;
        if(!res['error']){
          this.altEmail = res['response'];
          this.resetPasswordPage = 2;
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.page_loader = false;
        this.alertHelper.showErrorAlert();
      })
    }
  }
  passwordRecovery(){
    if(this.emailAddress.trim() == ""){
      this.alertHelper.showOkAlert("Email required", "You must enter the email which you used while signing up.");
    }else if(!Utils.isValidEmail(this.emailAddress)){
      this.alertHelper.showOkAlert("Invalid Email", "Please enter a valid email address");
    }else{
      this.page_loader = true;
      this.auth.passRecovery({email: this.emailAddress}).subscribe((res)=>{
        this.page_loader = false;
        if(!res['error']){
          this.resetPasswordPage = 1;
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.page_loader = false;
        this.alertHelper.showErrorAlert();
      })
    }
  }
  passwordRecoveryAlt(){
    this.page_loader = true;
    this.auth.passRecoveryAlt({email: this.emailAddress}).subscribe((res)=>{
      this.page_loader = false;
      if(!res['error']){
        this.resetPasswordPage = 1;
      }else{
        this.alertHelper.showOkAlert("Error", res['response']);
      }
    }, (error)=>{
      this.page_loader = false;
      this.alertHelper.showErrorAlert();
    })
  }
  passwordRecoveryToken(){
    if(this.token.toString().trim() == ""){
      this.alertHelper.showOkAlert("No Token", "Please enter the token that was sent to your email")
    }else if(!isNumber(this.token)){
      this.alertHelper.showOkAlert("Invalid Token", "The token entered was invalid")
    }else{
      this.page_loader = true;
      this.auth.passRecoveryToken({email: this.emailAddress, token: this.token}).subscribe((res)=>{
        this.page_loader = false;
        if(!res['error']){
          this.resetPasswordPage = 3;
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.page_loader = false;
        this.alertHelper.showErrorAlert();
      })
    }
  }

  updatePassword(){
    var pass_err = false;
    var pass_err_text = ""
    if(this.passwords.password.trim() == ""){
      pass_err = true;
      pass_err_text = "Enter password";
    }else if(this.passwords.cpass.trim() == ""){
      pass_err = true;
      pass_err_text = "Enter confrim password";
    }else if(this.passwords.password.trim().length < 8 || this.passwords.password.trim().length > 16 ){
      pass_err = true;
      pass_err_text =  "Your password must be 8-16 characters long";
    }else if(this.passwords.password.trim() != this.passwords.cpass.trim()){
      pass_err = true
      pass_err_text =  "Password and confirm password does not match";
    }
    if (!pass_err) {
      this.page_loader = true;
      this.auth.passReset({email: this.emailAddress, token: this.token, password: this.passwords.password}).subscribe((res)=>{
        this.page_loader = false;
        if(!res['error']){
          this.pass_reset_process = false;
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.page_loader = false;
        this.alertHelper.showErrorAlert();
      })
    } else {
      this.alertHelper.showOkAlert("Error", pass_err_text);
    }
  }

  goBack(){
    this.resetPasswordPage = 0;
  }

  openLogin(){
    this.router.navigate(['/login']);
  }

}
