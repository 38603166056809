import { Utils } from './../helpers/utils';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AlertHelper } from './../helpers/alert-helper';
import { User } from 'src/app/models/user';
import { DashboardLayoutComponent, DashboardValues } from './../dashboard-layout/dashboard-layout.component';
import { Component, OnInit, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';
import * as $ from 'jquery';
import { NetworkService } from '../network/_services/network';
import moment from "moment";
import { PageSettingsModel, CommandModel, CommandClickEventArgs, FilterSettingsModel, IFilter  } from '@syncfusion/ej2-grids';
import { PagerModule } from '@syncfusion/ej2-angular-grids';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { ILoadedEventArgs, ChartTheme, IPointRenderEventArgs, DateTime } from '@syncfusion/ej2-charts';
import { Browser } from '@syncfusion/ej2-base';
import { TreatmentStatus } from '../treat-file/treat-file.component';
import { InternalDocumentType } from '../internal-document/internal-document.component';
import { FormControl } from '@angular/forms';
import { Helpers } from '../helpers';
import { CheckBoxSelectionService } from '@syncfusion/ej2-angular-dropdowns';
import { ChartData } from '@syncfusion/ej2-charts/src/chart/utils/get-data';
import { ChangeEventArgs } from '@syncfusion/ej2-inputs';
import { PaginatedResult } from '../helpers/Pagination';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit {

  parent: DashboardLayoutComponent;
  loggedUser: User;

  @ViewChild('filestatsgrid', {static: false}) public file_stats_grid: GridComponent;
  @ViewChild('filecatchgrid', {static: false}) public file_catch_grid: GridComponent;

  file_stats: {title: string, count: number, color: string, today: number, data: Array<{CorrespondenceNo: string, Status: number, CreatedAt: string, Recepients: string, DepartmentName: string, MinistryAgency: string}>};
  file_catch: Array<{CorrespondenceNo: string, submittedAt: string, Subject: string, files: Array<{fid: string, url: string}>}> = new Array();
  selected_view_file_catch: {CorrespondenceNo: string, submittedAt: string, Subject: string, files: Array<{fid: string, url: string}>}
  view_file_catch_files_async: boolean = true;
  view_file_catch_files: boolean = false;
  file_stats_show: boolean = false;
  file_stats_loader: boolean = false;
  new_meeting_show: boolean = false;
  meeting_details_show: boolean = false;
  selectedDate: any;
  todaysDate: string;
  allowEndTime = true;
  showTokenInput = false;
  tokenInputRecepientText: string = '';
  maxRecipients = 3;
  documentSearch: boolean = false;
  searchGridLoader = false;
  queryString = '';
  searchLoader = false;
  document: searchDoc[];
  doctype = '';
  searchPattern = '';
  ArchvieDocResultsReturned = false;
  CorresspondenceResultsReturned = false;
  searchResultsReturned = false;
  ArchiveLoader = false;
  CorresspondenceLoader = false;
  hideViewInterface: any

  currentDateToDisplay = moment(new Date()).format('Do MMMM YYYY');
  isMeetingInitiator = false;


  hideMeetingRequirements = false;
  sendMeetingNow = false;
  meetingLoader = false;
  eventsInviteLoader = false;
  update_meeting_show = false;

  // tslint:disable-next-line: max-line-length
  submission: {initiator: string, description: string, meetingTitle: string, attendees: number[], startTime: Date, allDay: boolean, endTime: Date, recurring: boolean, location: string, eventType: number, attendeesEmail: string[], personalEventDate: Date, startRealTime: string, endRealTime: string} = {initiator: '', description: '',meetingTitle: '', allDay: false,  attendees: [], startTime: null, endTime: null, recurring: false, location: '', eventType: null, attendeesEmail: [], personalEventDate: null, startRealTime: '', endRealTime: ''};
  // public dateValue: Date = new Date('9/15/2019');
  // public dateValues: Date[] = [new Date('9/1/2019'), new Date('9/15/2019'), new Date('9/3/2019'), new Date('9/25/2019'), new Date('10/15/2019'), new Date('10/5/2019') ];
  dateValuez: Date[] = [];
  miniEventdata: Object[] = [];
  images = [{id: "1", url: "assets/img/why-piggy-min.svg"},{id: "2", url: "assets/img/why-piggy-min.svg"}]

  int_doc: {title: string, count: number, today: number, show: boolean, loader: boolean, type: string, data: Array<any>} = {title: "", show: false, loader: false, type: "", count: 0, today: 0, data: []};
;
  searchRequest = new searchRequest();
  requesting = false;
  requestedPage = 1;



  public pageSettings: PageSettingsModel;
  public toolbar: string[];
  public fc_view: CommandModel[];
  public fc_open: CommandModel[];
//   public departmentColleagues: Object[] =  [
//     { id: 1, display: 'Yusuf Hamza Tukur (Scientific Officer 1)' },
//     { id: 2, display: 'Sylvia John Diggles (Deputy Director 1)' },
//     { id: 3, display: 'Joe John Allen (Deputy Director 2)' },
//     { id: 4, display: 'Garba Shehu (Director)' },
//     { id: 5, display: 'Wilson Smith (Scientific Officer 2)' }
// ];
  selectedItems: string[];
// maps the appropriate column to fields property
// public fields: Object = { text: 'display', value: 'id' };
// // set placeholder to MultiSelect input element
// public placeholder: string = 'Please select attendees';

// Setting up the dropdown for the department and users for meeting

 //define the data with category
 public departmentColleagues: { [key: string]: Object }[] = [
  { display: 'Cabbage', deptName: 'E-government', id: 1 },
  { id: 2, deptName: 'E-government', display: 'Yusuf Hamza Tukur (Scientific Officer 1)' },
  { display: 'Fin Balor (Scientific Officer 2)', deptName: 'E-government', id: 3 },
  { display: 'Wilson Smith (Scientific Officer 2)', deptName: 'E-government', id: 4 },
  { display: 'Hameed Shehu (Director)', deptName: 'E-government', id: 5 },
  { display: 'John Shehu (Director)', deptName: 'E-government', id: 6 },
  { display: 'Tukur Shehu (Director)', deptName: 'Economic Planning', id: 7 },
  { display: 'Garba Shehu (Director)', deptName: 'Economic Planning', id: 8 },
  { display: 'Garba Shehu (Director)', deptName: 'Economic Planning', id: 9 },
  { display: 'Garba Shehu (Director)', deptName: 'Research and Statistics', id: 10 },
  { display: 'Garba Shehu (Director)', deptName: 'Research and Statistics', id: 11 },
  { display: 'Garba Shehu (Director)', deptName: 'Research and Statistics', id: 12 }
];

public fetchedDeptUsers: {[key: string]: Object }[];
// map the groupBy field with category column
public fields: Object = { groupBy: 'deptName', text: 'userName', value: 'userId' };
// Set the popup list height
public height: string = '200px';
// set the placeholder to the MultiSelect input
public placeholder: string = 'Select attendees from any department';
// set value of enableGroupCheckBox to true
enableGroupCheckBox: boolean = true;
// set value of allowFiltering to true
allowFiltering: boolean = true;
// set the placeholder to the filterbar
filterBarPlaceholder: string = "Search by full name";
// set the value of showSelectAll as true
showSelectAll: boolean = true;
departments_load_async = false;
public mode: string;


  show_file_catch:boolean = false;
  file_catch_loader: boolean = false;

  currentDate = new Date();


private isValidEmail(control: FormControl) {
  if (!Utils.isValidEmail(control.value)) {
      return {
          'isValidEmail@': true
      };
  }
  return null;
}

// tslint:disable-next-line: member-ordering
public validators = [this.isValidEmail];


public errorMessages = {
  'isValidEmail@': 'The email is invalid'
};

  // dashboard_vars = { ministries_agencies: [ { id: "", name: "", departments: [ { name: "", id: "" }, { name: "", id: "" } ] } ], positions: [ { id: "", title: "" } ], max_number_of_files: 5, max_files_size: 5, max_recepients: 5, pending_files: { count: 17, today: 1 }, uploaded_files: { count: 17, today: 1 }, treated_files: { count: 17, today: 1 }, notifications: [ { id: 1, title: "", message: "" } ] };


  dashboard_vars = new DashboardValues();
  alertHelper: AlertHelper;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  tasks_show: boolean = false;
  tasks_loader: boolean = false;
  tasks_data: Array<TaskItem> = new Array();

  chartLoader = false;

  public treatment_history: Object[] = [];

  // tslint:disable-next-line: member-ordering
  public cData: { month: string, productivity: string }[] = [
    { "month": 'Jan', "productivity": "0" },
    { "month": 'Feb', "productivity": "0" },
    { "month": 'Mar', "productivity": "0" },
    { "month": 'Apr', "productivity": "0" },
    { "month": 'May', "productivity": "0" },
    { "month": 'Jun', "productivity": "0" },
    { "month": 'Jul', "productivity": "0" },
    { "month": 'Aug', "productivity": "0" },
    { "month": 'Sep', "productivity": "0" },
    { "month": 'Oct', "productivity": "0" },
    { "month": 'Nov', "productivity": "0" },
    { "month": 'Dec', "productivity": "0" }
  ];
  public emptySeries1: Object;
  public emptySeries2: Object;
  //Initializing Primary X Axis
  public primaryXAxis: Object = {
      valueType: 'Category', /*interval: 1,*/ majorGridLines: { width: 0 }
  };
  //Initializing Primary Y Axis
  public primaryYAxis: Object = {
      //majorGridLines: { width: 0 },
      majorTickLines: { width: 0 }, lineStyle: { width: 0 },
      minimum: 0, maximum: 100,
      interval: 20, title: 'Productivity Ratio(%)'
  };

  public marker: Object = { dataLabel: { visible: true, position: 'Top', font: { fontWeight: '600', color: '#ffffff' } } }
  public title: string = 'Treatment history over time';
  public tooltip: Object = {
      enable: true
  };
  public animation: Object;
    // custom code start
  public load(args: ILoadedEventArgs): void {
      let selectedTheme: string = location.hash.split('/')[1];
      selectedTheme = selectedTheme ? selectedTheme : 'Bootstrap';
      args.chart.theme = <ChartTheme>(selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark");
      if(selectedTheme === 'highcontrast'){
      args.chart.series[0].marker.dataLabel.font.color= '#000000';
      args.chart.series[1].marker.dataLabel.font.color= '#000000';
      args.chart.series[2].marker.dataLabel.font.color= '#000000';
      }
  };
    // custom code end
  public chartArea: Object = {
      border: {
          width: 0
      }
  };
  public width: string = Browser.isDevice ? '100%' : '100%';
  public radius: Object = { bottomLeft: 0, bottomRight: 0, topLeft: 5, topRight: 5 }
  public pointRender(args: IPointRenderEventArgs): void {
    let seriesColor: string[] = ['#00bdae', '#404041', '#357cd2', '#e56590', '#f8b883',
            '#70ad47', '#dd8abd', '#7f84e8', '#7bb4eb', '#ea7a57'];
    args.fill = "#28a745";
};




public filterOptions: FilterSettingsModel;
public checkBoxFilter: IFilter;

public initialPage: PageSettingsModel;



  constructor(private network: NetworkService, private dialog: MatDialog, private router: Router, private cd: ChangeDetectorRef) {
    this.loggedUser = User.getCurrentUser();
    this.alertHelper = new AlertHelper(dialog);
  }

  ngOnInit() {
    setTimeout(() => {
      //this.loadCircularProgrss();
      this.loadTreatmentHistory();
    }, 500);
    this.filterOptions = {type: 'Menu'};
    this.checkBoxFilter = {type: 'CheckBox'};

    this.pageSettings = { pageSize: 5 };
    this.toolbar = ['Search'];
    this.fc_view = [{ buttonOption: { content: 'View files', cssClass: 'e-flat' } }];
    this.fc_open = [{ buttonOption: { content: 'Open', cssClass: 'e-flat' } }];
    this.mode = 'CheckBox';
    this.fetchUserEvents();
    this.emptySeries1 = {
      mode: 'Gap'
    }
    this.emptySeries2 = {
        mode:'Average'
    }
    this.animation = { enable: true};

    this.initialPage = { pageSize:5, currentPage: 1 };

    var passwordStatus = JSON.parse(localStorage.getItem(Helpers.STORAGE_TAG));
    console.log(passwordStatus);
    if(passwordStatus.PasswordChanged === false) {
      this.router.navigate(['/change-password']);
      this.alertHelper.showOkAlert('Info', 'This is your first login, please change your password');
    }

  }


  loadTreatmentHistory(){
    this.treatment_history = this.dashboard_vars.treatment_history;
    this.cData.map((item) => {
      this.treatment_history.forEach((val) => {
        if (val['month'] == item['month']) {
          item['productivity'] = val['productivity'];
        }
      });
    });
    //console.log(this.cData);;

    // if(this.treatment_history.length <= 0){
    //   for (let index = 0; index < 12; index++) {
    //     var month = moment().subtract(index, "months").format("MMM");
    //     var rand = Utils.getRandomInt(7,25);
    //     //this.treatment_history.push({x: month, y: (index+ rand) * Utils.getRandomInt(1,3)})
    //     this.treatment_history.push({x: month, y: 0})
    //   }
    //   this.treatment_history.reverse();
    // }

    //console.log(JSON.stringify(this.treatment_history));
  }

  loadCircularProgrss(){
    var _this = this;
    $(".progress").each(function() {

      var value = $(this).attr('data-value');
      var left = $(this).find('.progress-left .progress-bar');
      var right = $(this).find('.progress-right .progress-bar');


      if (value > 0) {
        if (value <= 50) {
          right.css('transform', 'rotate(' + _this.percentageToDegrees(value) + 'deg)')
          right.css('transition', 'transform 2.20s')
        } else {
          right.css('transform', 'rotate(180deg)')
          right.css('transition', 'transform 2.00s')
          setTimeout(function(){
            left.css('transform', 'rotate(' + _this.percentageToDegrees(value - 50) + 'deg)')
            left.css('transition', 'transform 2.20s')
          },2100)
        }
      }

    })
  }

  percentageToDegrees(percentage) {
    return percentage / 100 * 360
  }

  badgeMeterPercentage(){
    try {
      var rat = ((this.dashboard_vars.points_to_get_badge - this.dashboard_vars.points_left_to_badge)/this.dashboard_vars.points_to_get_badge) * 100
      if(isNaN(rat)){
        return "0%";
      }
      return rat + "%";
    } catch (error) {
      return "0%";
    }
  }

  setDashBoardVars(vars){
    this.dashboard_vars = vars;
  }

  closeFileStats(){
    this.file_stats_show = false;
  }

  closeNewMeeting(){
    this.new_meeting_show = false;
  }

  closeUpdateMeeting() {
    this.update_meeting_show = false;
  }

  closeMeetingDetails(){
    this.meeting_details_show = false;
    this.emptyEnteredData();
  }

  reloadFileStatsDataTable(){
    // this.datatableElement.dtInstance.then((dInstance: DataTables.Api)=>{
    //   dInstance.draw();
    // })
  }

  memos_data = [];
  memos(){
    this.int_doc = {title: "Memos", type: "memo", show: true, loader: true, count: this.dashboard_vars.memos.count, today: this.dashboard_vars.memos.today, data: this.memos_data};
    this.network.sendRequest({}, NetworkService.GET_MEMOS).subscribe((res) => {
      if(!res["error"]){
        this.int_doc.data = JSON.parse(res["response"])
        this.int_doc.data.forEach((item) => {
          item['status'] = this.getTreatmentStatus(item['status']);
          item['submittedAt'] = moment(item['date']).format("MMMM Do YYYY, h:mm a")
        });
        this.memos_data = this.int_doc.data;
      }else{
      }
      this.int_doc.loader = false;
    }, (error)=>{
      this.int_doc.loader = false;
    })
  }

  minutes_data = [];
  minutes(){
    this.int_doc = {title: "Minutes", type: "minute", show: true, loader: true, count: this.dashboard_vars.minutes.count, today: this.dashboard_vars.minutes.today, data: this.minutes_data};
    this.network.sendRequest({}, NetworkService.GET_MINUTES).subscribe((res) => {
      if(!res["error"]){
        this.int_doc.data = JSON.parse(res["response"])
        this.int_doc.data.forEach((item) => {
          item['submittedAt'] = moment(item['date']).format("MMMM Do YYYY, h:mm a")
        });
        this.minutes_data = this.int_doc.data;
      }else{
      }
      this.int_doc.loader = false;
    }, (error)=>{
      this.int_doc.loader = false;
    })
  }

  circular_data = [];
  circulars(){
    this.int_doc = {title: "Circulars", type: "circular", show: true, loader: true, count: this.dashboard_vars.circulars.count, today: this.dashboard_vars.circulars.today, data: this.circular_data};
    this.network.sendRequest({}, NetworkService.GET_CIRCULARS).subscribe((res) => {
      if(!res["error"]){
        this.int_doc.data = JSON.parse(res["response"])
        this.int_doc.data.forEach((item) => {
          item['status'] = this.getTreatmentStatus(item['status']);
          item['submittedAt'] = moment(item['date']).format("MMMM Do YYYY, h:mm a")
        });
        this.circular_data = this.int_doc.data;
      }else{
      }
      this.int_doc.loader = false;
    }, (error)=>{
      this.int_doc.loader = false;
    })
  }



  getTreatmentStatus(status){
    switch (status) {
      case TreatmentStatus.Pending:
        return '<span class="ds-yellow-text">Pending</span>';
        break;
      case TreatmentStatus.Rejected:
        return '<span class="text-danger">Rejected</span>';
        break;
      case TreatmentStatus.Treated:
        return '<span class="ds-green-text">Treated</span>';
        break;
      case TreatmentStatus.Untreated:
        return "Untreated";
        break;
      default:
        break;
    }
  }
  rowDblClickInternalDocument(args){
    var sub_ref: string = args.rowData['Ref'];
    sub_ref = sub_ref.replace("#", "");
    if(sub_ref){
      this.router.navigate(['/document',this.int_doc.type, sub_ref]);
    }
  }

  closeIntDoc(){
    this.int_doc.show = false;
  }

  createNewMeeting() {
    this.new_meeting_show = true;
    this.submission.initiator = User.getCurrentUser().email;
  }

  viewMeetingDetails() {
    this.meeting_details_show = true;
  }

  waitingFiles(){
    this.file_stats = {title: "Waiting Files", count: this.dashboard_vars.awaiting_files.count, today: this.dashboard_vars.awaiting_files.today, color: "red", data: []};
    this.file_stats_show = true;
    this.file_stats_loader = true;
    this.network.sendFileJacketRequest({}, NetworkService.GET_ALL_SUBMISSION).subscribe((res) => {
      if(!res["error"]){
        this.file_stats.data = JSON.parse(res["response"])
        this.file_stats.data.forEach((item) => item['SubmittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY, h:mm a"));
      }else{
      }
      this.file_stats_loader = false;
      this.reloadFileStatsDataTable();
    }, (error)=>{
      this.file_stats_loader = false;
      this.reloadFileStatsDataTable();
    })
  }


  untreatedFiles(){
    this.file_stats = {title: "Untreated Files", count: this.dashboard_vars.untreated_files.count, today: this.dashboard_vars.untreated_files.today, color: "blue", data: []};
    this.file_stats_show = true;
    this.file_stats_loader = true;
    this.network.sendRequest({value: 7}, NetworkService.GET_FILE_LIST).subscribe((res) => {
      if(!res["error"]){
        this.file_stats.data = JSON.parse(res["response"])
        this.file_stats.data.forEach((item) => item['SubmittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY, h:mm a"));
      }else{
      }
      this.file_stats_loader = false;
      this.reloadFileStatsDataTable();
    }, (error)=>{
      this.file_stats_loader = false;
      this.reloadFileStatsDataTable();
    })
  }

  pendingFiles(){
    this.file_stats = {title: "Pending Files", count: this.dashboard_vars.pending_files.count, today: this.dashboard_vars.pending_files.today, color: "yellow", data: []};
    this.file_stats_show = true;
    this.file_stats_loader = true;
    this.network.sendRequest({value: 8}, NetworkService.GET_FILE_LIST).subscribe((res) => {
      if(!res["error"]){
        this.file_stats.data = JSON.parse(res["response"])
        this.file_stats.data.forEach((item) => item['SubmittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY, h:mm a"));
      }else{
      }
      this.reloadFileStatsDataTable();
      this.file_stats_loader = false;
    }, (error)=>{
      this.file_stats_loader = false;
      this.reloadFileStatsDataTable();
    })
  }

  treatedFiles(){
    this.file_stats = {title: "Treated Files", count: this.dashboard_vars.treated_files.count, today: this.dashboard_vars.treated_files.today, color: "green", data: []};
    this.file_stats_show = true;
    this.file_stats_loader = true;
    this.network.sendRequest({value: 9}, NetworkService.GET_FILE_LIST).subscribe((res) => {
      if(!res["error"]){
        this.file_stats.data = JSON.parse(res["response"])
        this.file_stats.data.forEach((item) => item['SubmittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY, h:mm a"));
      }else{
      }
      this.reloadFileStatsDataTable();
      this.file_stats_loader = false;
    }, (error)=>{
      this.file_stats_loader = false;
      this.reloadFileStatsDataTable();
    })
  }

  rowDblClickOpenFile(args){
    var sub_ref: string = args.rowData['CorrespondenceNo'];
    let type = args.rowData['isArchiveDocument'];

    sub_ref = sub_ref.replace("#", "");
    if(sub_ref){
      if (type) {
        this.router.navigate(['/view-archive', sub_ref]);
      } else {
        this.router.navigate(['/treat-file', sub_ref]);
      }
    }
  }

  fcCommandClick(args: CommandClickEventArgs): void {
    // console.log(args);
    // alert(JSON.stringify(args.rowData));
    this.parent.hideScroll();
    this.view_file_catch_files_async = true;
    this.view_file_catch_files = true;
    this.selected_view_file_catch = JSON.parse(JSON.stringify(args.rowData));
    console.log(this.selected_view_file_catch);
  }

  currentFileCatchFile = 0;
  prevFileCatchDoc() {
    if (this.currentFileCatchFile > 0) {
      this.currentFileCatchFile -= 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }
  nextFileCatchDoc() {
    if (this.currentFileCatchFile < this.selected_view_file_catch.files.length - 1) {
      this.currentFileCatchFile += 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }

  showFileCatch(){
    this.show_file_catch = true;
    this.file_catch_loader = true;
    this.network.sendRequest({}, NetworkService.FILE_CATCH).subscribe((res)=>{
      if(!res['error']){
        var files: Array<any> = JSON.parse(res['response']);
        files.forEach((item) => {
          item['submittedAt'] = moment(item['CreatedAt']).format("MMMM Do YYYY")
          // item['files'].forEach(element => {
          //   element.url = "https://s1.q4cdn.com/806093406/files/doc_downloads/test.pdf";
          // });
        })
        this.file_catch = files;
        this.file_catch_loader = false;
      }else{
        this.file_catch_loader = false;
        this.alertHelper.showOkAlert("Error", res['response']);
      }
    }, (error) =>{
      this.file_catch_loader = false;
      this.alertHelper.showErrorAlert();
    })
  }

  fsDataBound(){
    //this.file_stats_grid.autoFitColumns(['copiedRecipients', 'SubmittedAt']);
  }

  fcDataBound(){
    //this.file_catch_grid.autoFitColumns();
  }

  docsLoaded(event?){
    if(this.view_file_catch_files_async){
      this.view_file_catch_files_async = false
    }
  }

  closeViewFileCatchFiles(){
    this.view_file_catch_files = false;
    this.parent.showScroll();
  }

  navigate(route){
    this.router.navigate(['/'+ route]);
  }



  selected_task: TaskItem = new TaskItem();
  show_task_details: boolean = false;

  showTasks(){
    this.tasks_show = true;
    this.tasks_loader = true;
    this.network.sendRequest({}, NetworkService.GET_TASKS).subscribe((res)=>{
      if(!res['error']){
        var tasks: Array<any> = JSON.parse(res['response']);
        tasks = tasks.sort( (a,b) => a.date.localeCompare(b.date) ).reverse();
        tasks.forEach((item) => {
          item['time'] = moment(item['date']).format("MMMM Do YYYY")
        })
        this.tasks_data = tasks;
        this.tasks_loader = false;
      }else{
        this.tasks_loader = false;
        this.alertHelper.showOkAlert("Error", res['response']);
      }
    }, (error) =>{
      this.tasks_loader = false;
      this.alertHelper.showErrorAlert();
    })
  }


  closeTasks(){
    this.tasks_show = false;
  }

  closeViewTaskdetails(){
    this.show_task_details = false;
    this.parent.showScroll();
  }

  rowDblClickOpenTask(args){
    this.selected_task = JSON.parse(JSON.stringify(args.rowData));
    //if(this.selected_task.files && this.selected_task.files.length > 0){
    this.parent.hideScroll();
    this.show_task_details = true;
    //}
  }

  // Calendar Related Functions starts here
  number_of_event_for_this_date = 0;
  checkSelectedDate() {
    this.selectedDate = moment(this.selectedDate).format('Do MMMM YYYY');
    var today = new Date();
    this.todaysDate = moment(today).format('Do MMMM YYYY');
    this.number_of_event_for_this_date = 0;
    if (this.miniEventdata.length > 0) {
      this.miniEventdata.forEach(element => {
        if (element['date'] == this.selectedDate) {
          this.number_of_event_for_this_date += 1;
        }
      });
    }
  }

  checkEndTime() {
    if(this.allowEndTime) {
      this.allowEndTime = false;
      this.submission.allDay = true;
    } else {
      this.allowEndTime = true;
      this.submission.allDay = false;
    }
  }

  checkRecurring() {
    if(this.submission.recurring) {
      this.submission.recurring = false;
    } else {
      this.submission.recurring = true;
    }
  }

  customDates(args): void {
    let span: HTMLElement;
    // defines the custom HTML element to be added.
    span = document.createElement('span');
    // Use "e-icons" class name to load Essential JS 2 built-in icons.
    span.setAttribute('class', 'e-icons highlight-day');
    // Know that the date count starts from month 0 to 11 here....
    this.dateValuez.forEach(element => {
      if (+args.date.getDate() === element.getDate() && +args.date.getMonth() == element.getMonth()) {
        // append the span element to day cell.
        args.element.appendChild(span);
        // set the custom tooltip to the special dates.
        // Use "special" class name to highlight the special dates, which you can refer in "styles.css".
        args.element.className = 'special';
      }
    });
}

onFocusForToken() {
  this.showTokenInput = true;
  setTimeout(() => {
    var tokenInput = document.getElementById('reps-token');
    tokenInput.focus();
  }, 300);

}

checkEventType() {
  if (this.submission.eventType == 1) {
    this.hideMeetingRequirements = true;
  } else if (this.submission.eventType == 2){
    this.hideMeetingRequirements = false;
  }
}


 saveNewMeeting() {
  if(this.submission.meetingTitle == '' ) {
    this.alertHelper.showOkAlert('Error', 'Meeting title can not be empty');
    return;
  }
  if (this.submission.description == '' ) {
    this.alertHelper.showOkAlert('Error', 'Description can not be empty');
    return;
  }
  if (this.submission.eventType == null) {
    this.alertHelper.showOkAlert('Error', 'Please select an event type');
    return;
  }
  if (this.submission.eventType == 1) {
    if (this.submission.personalEventDate == null) {
    this.alertHelper.showOkAlert('Error', 'You need to set a date for this personal event');
    return;
    }
  } else {
    this.submission.personalEventDate = null;
    if (this.submission.attendees.length == 0) {
    this.alertHelper.showOkAlert('Error', 'You need to enter at least one attendee');
    return;
    }
    if (this.submission.startTime == null ) {
    this.alertHelper.showOkAlert('Error', 'Start Date and Time not specified');
    return;
    }
    if (this.submission.startTime.getMonth() < this.currentDate.getMonth()) {
      this.alertHelper.showOkAlert('Error', 'The specified start date is wrong');
      return;
    }
    if (this.submission.startTime.getMonth() == this.currentDate.getMonth()) {
      if (this.submission.startTime.getDate() < this.currentDate.getDate()) {
      this.alertHelper.showOkAlert('Error', 'The specified start date is wrong');
      return;
      }
    }

    if (this.submission.startTime.getDate() == this.currentDate.getDate()) {
      if (this.submission.startTime.getTime() <= this.currentDate.getTime()) {
        this.alertHelper.showOkAlert('Error', 'The specified start time is wrong');
        return;
      }
    }
    if (this.submission.allDay) {
      this.submission.endTime == null;
    } else {

      if (this.submission.endTime == null ) {
      this.alertHelper.showOkAlert('Error', 'End date and time needs to be specified since the meeting is not an all day event');
      return;
      } else {

      if (this.submission.endTime.getMonth() < this.submission.startTime.getMonth()) {
        this.alertHelper.showOkAlert('Error', 'Your end date can not be less than the start date');
        return;
      }

      if (this.submission.endTime.getMonth() == this.submission.startTime.getMonth()) {
        if (this.submission.endTime.getDate() < this.submission.startTime.getDate()) {
        this.alertHelper.showOkAlert('Error', 'Your end date can not be less than the start date');
        return;
        }

        if ( this.submission.startTime.getTime() > this.submission.endTime.getTime() ) {
          this.alertHelper.showOkAlert('Error', 'Your end time can not be behind the start time');
          return;
        }
      }
    }

      if (this.submission.recurring) {
        if (this.submission.endTime.getMonth() < this.submission.startTime.getMonth()) {
          this.alertHelper.showOkAlert('Error', 'End date cannot be same day or a day less than the start date');
          return;
        }



        if (this.submission.endTime.getMonth() == this.submission.startTime.getMonth()) {
          if (this.submission.endTime.getDate() <= this.submission.startTime.getDate()) {
            this.alertHelper.showOkAlert('Error', 'End date cannot be same day or a day less than the start date in a recurring event');
            return;
          }
        }
      }
    }
    if (this.submission.location == '') {
      this.alertHelper.showOkAlert('Error', 'Please specify a location for this meeting');
      return;
    }
  }
  this.submission.attendees.forEach(element => {
    this.submission.attendeesEmail.push(element['value']);
  });
  // Make the freaking request and be done with this shit.
  this.sendMeetingNow = true;
  this.network.sendRequest(this.submission, NetworkService.CREATE_NEW_EVENT).subscribe((result) => {
    if(!result['error']) {
      this.sendMeetingNow = false;
      this.alertHelper.showOkAlert('Success', result['response'], () => {
        this.closeNewMeeting();
        this.fetchUserEvents();
      });
    } else {
      this.sendMeetingNow = false;
      this.alertHelper.showOkAlert('Error', result['response']);
    }
  }, (error) => {
    this.sendMeetingNow = false;
    this.alertHelper.showErrorAlert();
  });
 }


 fetchTheDepartmentUsers() {
   this.network.sendRequest({}, NetworkService.FETCH_USERS_AND_DEPT_FOR_MEETING).subscribe((result) => {
     if (!result['error']) {
       this.fetchedDeptUsers = JSON.parse(result['response']);
       this.getEventsInvite();
     }
   });
 }


 emptyEnteredData() {
   this.submission == {initiator: '', description: '',meetingTitle: '', allDay: false,  attendees: [], startTime: null, endTime: null, recurring: false, location: '', eventType: null, attendeesEmail: [], personalEventDate: null, startRealTime: '', endRealTime: ''};
 }

 cancelMeeting(eventRef: string) {
   this.alertHelper.showYesNoAlert('Warning', 'Are you sure you want to cancel this meeting?',
   () => {
    this.network.sendRequest({eventRef}, NetworkService.CANCEL_EVENT).subscribe((result) => {
      if (!result['error']) {
        this.alertHelper.showOkAlert('Success', 'Event Cancelled successfully' , () => {
          this.fetchUserEvents();
        }, 3000);
      } else {
        this.alertHelper.showOkAlert('Error', result['response']);
      }
    }, (error) => {
      this.alertHelper.showErrorAlert();
    });
   });
 }

//  cancelCapability = false;
//  editCapability = false;




 fetchUserEvents() {
  this.meetingLoader = true;
  this.network.sendRequest({}, NetworkService.GET_EVENT).subscribe((result) => {
    if(!result['error']) {
      this.meetingLoader = false;
      this.fetchTheDepartmentUsers();
      var rez = JSON.parse(result['response']);
      this.dateValuez = [];
      this.miniEventdata = [];
      rez.forEach(element => {
        if (element.deleted == false) {
          this.dateValuez.push(new Date(element.start_date));
        }
        var dates = moment(element.start_date).format('Do MMMM YYYY');
        var times = moment(element.start_date).format("h:mm a");
        var cancelCapability = false;
        var editCapability = false;
        if (element.initiator == User.getCurrentUser().email) {
          cancelCapability = true;
          editCapability = true;
        }
        if (element.deleted == false) {
          this.miniEventdata.push({subject: element.title, time: times, date: dates, eventRef: element.event_ref, venue: element.location, cancelCapability: cancelCapability, editCapability: editCapability, deleted: element.deleted});
        }
      });
      var today = new Date();
      this.selectedDate = today;
      this.checkSelectedDate();

    } else {
      this.meetingLoader = false;
      this.alertHelper.showOkAlert('Error', result['response']);
    }
  }, (error) => {
    this.meetingLoader = false;
    this.alertHelper.showErrorAlert();
  });
 }

 meetingDetailsLoader = false;

 fullMeetingData: {};

 fetchSingleUserEvents(eventRef) {
  this.meeting_details_show = true;
  this.meetingDetailsLoader = true;
  this.network.sendRequest({eventRef}, NetworkService.GET_EVENT_BY_ID).subscribe((result) => {
    if(!result['error']) {
      this.meetingDetailsLoader = false;
      var rez = JSON.parse(result['response']);
      if(rez.initiator == User.getCurrentUser().email) {
        this.isMeetingInitiator = true;
      }
      var start_dates = moment(rez.start_date).format('Do MMMM YYYY');
      var start_times = moment(rez.start_date).format("h:mm a");
      var end_dates = moment(rez.end_date).format('Do MMMM YYYY');
      var end_times = moment(rez.end_date).format("h:mm a");
      var attendees = JSON.parse(rez.attendees);
      var confirmed_attendees = JSON.parse(rez.confirmed_attendees);
      console.log(attendees);
      this.fullMeetingData = {title: rez.title, description: rez.description, initiator: rez.initiator, start: start_dates, s_time: start_times, end: end_dates, e_time: end_times, event_type: rez.event_type, venue: rez.location, attendees: attendees, confirmed_attendees: confirmed_attendees, deleted: rez.deleted};

    } else {
      this.meetingLoader = false;
      this.alertHelper.showOkAlert('Error', result['response']);
    }
  }, (error) => {
    this.meetingLoader = false;
    this.alertHelper.showErrorAlert();
  });
 }

 fetchEventForEditing(eventRef: string) {
  this.update_meeting_show = true;
  this.meetingDetailsLoader = true;
  this.network.sendRequest({eventRef}, NetworkService.GET_EVENT_BY_ID).subscribe((result) => {
    if(!result['error']) {
      this.meetingDetailsLoader = false;
      var rez = JSON.parse(result['response']);
      if(rez.initiator == User.getCurrentUser().email) {
        this.isMeetingInitiator = true;
      }
      var start_dates = moment(rez.start_date).format('Do MMMM YYYY');
      var start_times = moment(rez.start_date).format("h:mm a");
      var end_dates = moment(rez.end_date).format('Do MMMM YYYY');
      var end_times = moment(rez.end_date).format("h:mm a");
      var attendees = JSON.parse(rez.attendees);
      var confirmed_attendees = JSON.parse(rez.confirmed_attendees);
      console.log(attendees);
      this.fullMeetingData = {title: rez.title, description: rez.description, initiator: rez.initiator, start: start_dates, s_time: start_times, end: end_dates, e_time: end_times, event_type: rez.event_type, venue: rez.location, attendees: attendees, confirmed_attendees: confirmed_attendees};

    } else {
      this.meetingLoader = false;
      this.alertHelper.showOkAlert('Error', result['response']);
    }
  }, (error) => {
    this.meetingLoader = false;
    this.alertHelper.showErrorAlert();
  });
 }


 MeetingEventInvitesData: Object[] = [];
 noInvites = true;

 getEventsInvite() {
  this.eventsInviteLoader = true;
  this.network.sendRequest({}, NetworkService.GET_MEETING_INVITES).subscribe((result) => {
    if (!result['error']) {
      this.eventsInviteLoader = false;
      this.MeetingEventInvitesData = JSON.parse(result['response']);
      if (this.MeetingEventInvitesData.length > 0) {
        this.noInvites = false;
      }
      this.MeetingEventInvitesData.forEach((element)=> {
        element['end_date'] = moment(element['end_date']).format('Do MMMM YYYY h:mm a');
        element['start_date'] = moment(element['start_date']).format('Do MMMM YYYY h:mm a');
      });
    }
  }, (error) => {
    this.alertHelper.showOkAlert('Error', 'Error Fetching Invites', () => {
      this.getEventsInvite();
    });
  });
 }

    acceptInvite(eventRef: string) {
      this.alertHelper.showYesNoAlert('Success', 'Are you sure you want to accept this meeting invite?' ,
      () => {
        this.network.sendRequest({eventRef}, NetworkService.ACCEPT_INVITE).subscribe((result) => {
          if (!result['error']) {
            this.alertHelper.showOkAlert('Success', 'Event added to your calendar', () => {
              this.fetchUserEvents();
            });
          }
        }, (error) => {
          this.alertHelper.showErrorAlert();
        });
      });
    }

    rejectInvite(eventRef: string) {
      this.alertHelper.showYesNoAlert('Success', 'Are you sure you want to reject this meeting invite?' ,
      () => {
      this.network.sendRequest({eventRef}, NetworkService.REJECT_INVITE).subscribe((result) => {
        if (!result['error']) {
          this.alertHelper.showOkAlert('Success', 'Event rejected, nothing added to your calendar', () => {
            this.fetchUserEvents();
          });
        }
      }, (error) => {
        this.alertHelper.showErrorAlert();
      });
      });
    }







  // Calendar related Functions ends here





 search() {
  if (this.requesting) {
    return;
  }
  if (this.doctype === '') {
    return this.alertHelper.showOkAlert('Error', 'Please select a document type');
  } else if (this.searchPattern === '') {
   return this.alertHelper.showOkAlert('Error', 'Please select a document search pattern');
  } else if (this.queryString.length < 2) {
    return this.alertHelper.showOkAlert('Error', 'Please specify at least a two-character length keyword');
  }
  if (this.doctype === '4') {
    this.ArchvieDocResultsReturned = false;
    this.CorresspondenceLoader = true;
  } else if (this.doctype === '5') {
    this.CorresspondenceResultsReturned = false;
    this.ArchiveLoader = true;
  }

  this.searchRequest.doctype = this.doctype;
  this.searchRequest.searchPattern = this.searchPattern;
  this.searchRequest.queryString = this.queryString;
  this.searchRequest.pageNumber = this.initialPage.currentPage;
  this.searchRequest.pageSize = this.initialPage.pageSize;

  this.requesting = true;
  return this.network.pagedRequest(this.searchRequest, NetworkService.DOC_SEARCH).subscribe((response) => {
    if (!response['error']) {

      const paginatedResult: PaginatedResult<searchDoc[]> = new PaginatedResult<searchDoc[]>();
      paginatedResult.result = JSON.parse(response.body['response']);
      paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
      this.initialPage.pageCount = paginatedResult.pagination.totalPages > 0 ? paginatedResult.pagination.totalPages : 1;
      this.initialPage.pageSize = paginatedResult.pagination.itemsPerPage;
      this.initialPage.totalRecordsCount = paginatedResult.pagination.totalItems > 0 ? paginatedResult.pagination.totalItems : 0;
      this.initialPage.currentPage = paginatedResult.pagination.currentPage;

      this.document = null;
      this.document = paginatedResult.result;
      this.searchGridLoader = false;
      this.searchResultsReturned = true;

      if (this.doctype === '4') {
        this.CorresspondenceResultsReturned = true;
        this.CorresspondenceLoader = false;
      } else if (this.doctype === '5') {
        this.ArchvieDocResultsReturned = true;
        this.ArchiveLoader = false;
      }


      if (this.document.length < 1) {
        this.alertHelper.showOkAlert("Search", "No results were found");
        this.searchGridLoader = false;
      }
      this.requesting = false;
    } else {
      this.searchRequest = new searchRequest();
      this.requesting = false;
      return this.alertHelper.showOkAlert('Error', 'An error occured while processing the request. Please try again later.');
    }
  }, error => {
    this.searchGridLoader = false;
    this.searchResultsReturned = true;
    this.requesting = false;
    this.CorresspondenceLoader = false;
    this.ArchiveLoader = false;
    return this.alertHelper.showOkAlert('Error', 'Sorry an unexpected error occured');
  });
 }

 openSearchedDocument(args) {
  let sub_ref: string = args.rowData['FilesTrackingNo'];
  let doc = this.document.find((element) => {
   return element['FilesTrackingNo'] === sub_ref;
 });
  this.parent.searchedDocPermLevel = doc.perm;
  sub_ref = sub_ref.replace('#', '');
  if (doc.type === 'archive') {
   if (sub_ref) {
     this.router.navigate(['/view-archive', sub_ref]);
   }
  } else if (doc.type === 'correspondence') {
   if (sub_ref) {
     this.router.navigate(['/treat-file', sub_ref]);
   }
  }
}

 openSearchedArchivedDocument(args) {
   let sub_ref: string = args.rowData['FilesTrackingNo'];
   let doc = this.document.find((element) => {
    return element['FilesTrackingNo'] === sub_ref;
  });
   this.parent.searchedArchiveDocPermissionLevel = doc.perm;
   sub_ref = sub_ref.replace('#', '');
   if (doc.type === 'archive') {
    if (sub_ref) {
      this.router.navigate(['/view-archive', sub_ref]);
    }
   } else if (doc.type === 'correspondence') {
    if (sub_ref) {
      this.router.navigate(['/treat-file', sub_ref]);
    }
   }
 }

 openSearchedCorresspondenceDocument(args) {
  let sub_ref: string = args.rowData['FilesTrackingNo'];
  let doc = this.document.find((element) => {
   return element['FilesTrackingNo'] === sub_ref;
 });
  this.parent.searchedCorresspondenceDocPermissionLevel = doc.perm;
  sub_ref = sub_ref.replace('#', '');
  if (doc.type === 'archive') {
   if (sub_ref) {
     this.router.navigate(['/view-archive', sub_ref]);
   }
  } else if (doc.type === 'correspondence') {
   if (sub_ref) {
     this.router.navigate(['/treat-file', sub_ref]);
   }
  }
}

closeSearchModal() {
  this.documentSearch = false;
  this.CorresspondenceResultsReturned = false;
  this.ArchvieDocResultsReturned = false;
  this.doctype = "";
  this.queryString = '';
  this.searchPattern = "";
}

change(event) {
  if(event) {
    this.initialPage.currentPage = event.pageIndex + 1;
    this.search();
  }
}

log(val: string) {
  console.log(val);
}


// All tracking related functions starts here

  // Track file related functions starts here
  track_details = {ref_no: "#889d9SW",subject: "A Subject", status: 0, notifications: [ { stage: "Acknowlegdement", n_date: "2019/2/19", in_charge: 'Olu Jacobs', status: "Completed" } ] }
  track_file_show = false;

  track_ref_no: string = "";
  aysn_track_file: boolean = false;
  track_err: boolean = false;
  track_err_text: string = "";
  dash_track_err: boolean = false;
  dash_track_err_text: string = "";
  dash_track_ref: string = "";
  track_ref: string = "";
  track_file_shadow: boolean = false;
  closeTrackFile(){
    this.track_file_shadow = false;
    this.track_file_show = false;
  }
  getFullDate(dateString){
    return moment(dateString).format("MMMM Do YYYY, h:mm a")
  }
  modalTrackFile(){
    this.track_err = false;
    if(this.track_ref.trim() != ""){
      if(this.track_ref.trim().charAt(0) == '#' && this.track_ref.trim().length > 3){
        this.trackFile(this.track_ref);
      }else{
        this.track_err = true;
        this.track_err_text = "Invalid correspondence number.";
        this.alertHelper.showOkAlert('Error', this.track_err_text);
      }
    }else{
      this.track_err = true;
      this.track_err_text = "The correspondence number is required.";
      this.alertHelper.showOkAlert('Error', this.track_err_text);
    }
  }

  modalTrackFileInput(){
    if(this.track_ref.trim() != ""){
      if(this.track_ref.trim().charAt(0) != '#'){
        this.track_ref = '#' + this.track_ref;
      }else{
        var re = /(#)+/;
        this.track_ref = this.track_ref.replace(re, '#');
        this.cd.detectChanges();
      }
    }
  }

  modalTrackFileKeyPress(event: KeyboardEvent){
    if(event.keyCode == 13){
      this.modalTrackFile();
    }else{
      if((event.key == '#' || event.keyCode == 163) && this.track_ref.length > 0){
        if(this.track_ref.charAt(this.track_ref.length - 1) == '#'){
          event.preventDefault();
        }
      }
    }
  }

  //Track Files
    trackFile(ref?: string){
    this.track_err = false;
    this.track_file_shadow = true;
    this.track_file_show = true;
    console.log('here now');
    if(ref){
      this.aysn_track_file = true;
      this.track_details = null;
      this.network.sendRequestG2G({trackingRef: ref}, NetworkService.TRACK_FILE_G2G).subscribe((res)=>{
        if(!res['error']){
          this.track_details = JSON.parse(res['response']);
          console.log(this.track_details);
        }else{
          this.track_err = true;
          this.track_err_text = res['response'];
          this.alertHelper.showOkAlert('Error', this.track_err_text);
        }
        this.aysn_track_file = false
      }, (error) =>{
        this.aysn_track_file = false;
        this.track_err = true;
        this.track_err_text = "Sorry an unexpected error occured";
        this.alertHelper.showOkAlert('Error', this.track_err_text);
      })
    }
  }


  dashTrackFile(){
    this.dash_track_err = false;
    var err = this.dashTrackFileParent(this.dash_track_ref);
    if(typeof err == 'string'){
      this.dash_track_err = true;
      this.dash_track_err_text = err;
      this.alertHelper.showOkAlert('Error', this.dash_track_err_text);
    }
  }


  dashTrackFileParent(dash_ref) : void | string{
    this.dash_track_ref = dash_ref;

    this.dash_track_err = false;
    if(this.dash_track_ref.trim() != ""){
      if(this.dash_track_ref.trim().charAt(0) == '#' && this.dash_track_ref.trim().length > 3){
        this.trackFile(this.dash_track_ref);
      }else{
        return "Invalid correspondence number.";
      }
    }else{
      return "The correspondence number is required.";
    }
  }

  trackFileInput(){
    if(this.dash_track_ref.trim() != ""){
      if(this.dash_track_ref.trim().charAt(0) != '#'){
        this.dash_track_ref = '#' + this.dash_track_ref;
      }else{
        var re = /(#)+/;
        this.dash_track_ref = this.dash_track_ref.replace(re, '#');
      }
    }
  }

  trackFileKeyPress(event: KeyboardEvent){
    if(event.keyCode == 13){
      this.dashTrackFile();
    }else{
      if((event.key == '#' || event.keyCode == 163) && this.dash_track_ref.length > 0){
        if(this.dash_track_ref.charAt(this.dash_track_ref.length - 1) == '#'){
          event.preventDefault();
        }
      }
    }
  }

// All tracking related functions ends here


}

class TaskItem{
  id: string = "";
  message: string = "";
  time: string = "";
  tfrom: string = "";
  files: Array<{fid: string, url: string}> = new Array();
}

class searchRequest {
  doctype: string;
  searchPattern: string;
  queryString: string;
  //userParams = new userParams();
  pageSize: number;
  pageNumber: number;
}

class searchDoc {
  FilesTrackingNo: string;
  subject: string;
  date: string;
  sender: string;
  perm: string;
  type: string;
}

class userParams {
  pageNumber: string;
  pageSize: string;
}

