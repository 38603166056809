import { Router } from '@angular/router';
import { NetworkService } from './../network/_services/network';
import { Component, OnInit } from '@angular/core';
import { DashboardLayoutComponent } from '../dashboard-layout/dashboard-layout.component';
import { AlertHelper } from '../helpers/alert-helper';
import { User } from '../models/user';
import { MatDialog } from '@angular/material';
import { Helpers } from '../helpers';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {


  parent: DashboardLayoutComponent;
  loggedUser: User;
  usr_data = {CurrentPassword: "", NewPassword: "", cpass: ""};
  

  frm_err: boolean = false;
  frm_err_text: string = "";
  change_password_async: boolean = false;
  alertHelper: AlertHelper;

  constructor(private dialog: MatDialog, private network: NetworkService, private router: Router) {
    this.loggedUser = User.getCurrentUser();
    this.alertHelper = new AlertHelper(dialog);
   }

  ngOnInit() {
  }

  goHome(){
    this.router.navigate(['/']);
  }

  changePassword(){
    this.frm_err = false;
    if(this.usr_data.CurrentPassword.trim() == ""){
      this.frm_err = true;
      this.frm_err_text = "Enter current password";
    }else if(this.usr_data.NewPassword.trim() == ""){
      this.frm_err = true;
      this.frm_err_text = "Enter password";
    }else if(this.usr_data.cpass.trim() == ""){
      this.frm_err = true;
      this.frm_err_text = "Enter password repeat";
    }else if(this.usr_data.NewPassword.trim().length < 8 || this.usr_data.NewPassword.trim().length > 16 ){
      this.frm_err = true;
      this.frm_err_text = "Your password must be 8-16 characters long";
    }else if(this.usr_data.NewPassword.trim() != this.usr_data.cpass.trim()){
      this.frm_err = true;
      this.frm_err_text = "Password and password repeat does not match";
    }
    if(!this.frm_err){
      var data = JSON.parse(JSON.stringify(this.usr_data));
      data = delete data['cpass'];
      var CurrentPassword = this.usr_data.CurrentPassword;
      var NewPassword = this.usr_data.NewPassword;
      this.network.sendRequest({CurrentPassword, NewPassword}, NetworkService.CHANGE_PASSWORD).subscribe((res)=>{
        if(!res['error']){
          this.alertHelper.showOkAlert("Success", "Password changed successfully", ()=>{
            var passwordStatus = JSON.parse(localStorage.getItem(Helpers.STORAGE_TAG));
            passwordStatus.PasswordChanged = true;
            var newLSData = JSON.stringify(passwordStatus);
            localStorage.setItem(Helpers.STORAGE_TAG, newLSData);
            this.router.navigate(['/']);
          }, 5000)
        }else{
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error)=>{
        this.alertHelper.showErrorAlert();
      })
    }else{
      this.alertHelper.showOkAlert("Error", this.frm_err_text);
    }
  }

}
