import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-view-files-dialog',
  templateUrl: './view-files-dialog.component.html',
  styleUrls: ['./view-files-dialog.component.scss']
})
export class ViewFilesDialogComponent implements OnInit {

  @Input() show: boolean = false;
  @Input() hasInfo: boolean = false;
  @Input() infoHeader: string = "";
  @Input() infoMessage: string = "";
  view_files_async: boolean = true;
  @Input() files: Array<{fid: string, url: string}> = new Array(); 
  @Output() closeDialog: EventEmitter<any> = new EventEmitter();

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }

  currentViewFile = 0;
  

  
  constructor() { }

  ngOnInit() {
    this.view_files_async = true;
  }

  close(){
    this.show = false;
    this.closeDialog.emit();
  }

  prevViewDoc() {
    if (this.currentViewFile > 0) {
      this.currentViewFile -= 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }
  nextViewDoc() {
    if (this.currentViewFile < this.files.length - 1) {
      this.currentViewFile += 1;
      // this.currentFile = this.submission_info.files[this.current_doc];
    }
  }

  docsLoaded(event?){
    if(this.view_files_async){
      this.view_files_async = false
    }
  }

}
