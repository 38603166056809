import { WindowRef } from './../helpers/windowref';
import { MemosComponent } from './../memos/memos.component';
import { SREvent } from './../helpers/signalr-events';
import { AppComponent } from './../app.component';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { User } from '../models/user';
import { AlertHelper } from '../helpers/alert-helper';
import { Helpers } from '../helpers';
import { NetworkService } from '../network/_services/network';
import { CacheRequest } from '../models/cache';
import moment from "moment";
import { HubConnection, HubConnectionBuilder } from '@aspnet/signalr';
import { ChatpageComponent } from '../chatpage/chatpage.component';
import $ from 'jquery';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { TreatFileComponent } from '../treat-file/treat-file.component';
import { InternalDocumentType } from '../internal-document/internal-document.component';
import { HttpEventType } from '@angular/common/http';
import { UserIdleService } from 'angular-user-idle';
import {Howl, Howler} from 'howler';

enum DashBoardSkin{
  Light = 1,
  Advanced = 2
}

declare var JQTE: any

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})

export class DashboardLayoutComponent implements OnInit {

  private hubConnection: HubConnection;
  DEFAULT_RETRY_MILLIS = 5000;
  show_connection_retry: boolean = false;
  retry_connection_time = 5000; //5 secs
  connection_text: string = "Connection failed. Retrying in ";
  retry_connection_async: boolean = false;
  connection_interval_id;

  @ViewChild('audioOption', {static: false}) audioPlayerRef: ElementRef;


  departments_load_async: boolean = false;
  department_users_load_async: boolean = false;
  inst_users_load_async: boolean = false;

  submission_departments: {id: string, name: string}[] = new Array();
  department_users: {id: string, name: string, level: number, position: string}[] = new Array();
  toggle_users_dropdown: boolean = true;
  selected_route_sub_user: {id: string, name: string, level: number, position: string};
  inst_users: {id: string, name: string, level: number, position: string}[] = new Array();
  selected_inst_users: {id: string, name: string, level: number, position: string}[] = new Array();
  sel_department: {id: string, name: string};
  config = {
    displayKey:"name", //if objects array passed which key to be displayed defaults to description
    search:true, //true/false for the search functionlity defaults to false,
    height: '200px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder:'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: ()=>{}, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    //limitTo: 10, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder:'Search', // label thats displayed in search input,
    searchOnKey: 'name' // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
  }

  parent: AppComponent
  currentPage
  currentPageName: string = "";
  alertHelper: AlertHelper;
  show_sidebar: boolean = false;
  loggedUser: User;

  preloader: boolean = false;
  preload_err: boolean = false;

  public dashboard_skin = DashBoardSkin.Advanced;

  show_notification: boolean = false;

  show_memo: boolean = false;
  show_memo_async: boolean = false;
  memo_req: MemoLetterMinuteRequest = new MemoLetterMinuteRequest();
  memo_letter_minute: {show: boolean, async: boolean, name: string, req: MemoLetterMinuteRequest} = {show: false, async: false, name: "", req: new MemoLetterMinuteRequest()};
  circular_type: number = 1;
  // dashboard_vars = { ministries_agencies: [ { id: "", name: "", departments: [ { name: "", id: "" }, { name: "", id: "" } ] } ], positions: [ { id: "", title: "" } ], max_number_of_files: 5, max_files_size: 5, max_recepients: 5, pending_files: { count: 17, today: 1 }, uploaded_files: { count: 17, today: 1 }, treated_files: { count: 17, today: 1 }, notifications: [ { id: 1, title: "", message: "" } ] };
  dashboard_vars = new DashboardValues();

  notifications: {today: Array<NotificationItem>, older: Array<NotificationItem>} = {today: new Array(), older: new Array()};

  new_noti: boolean = false;
  int_doc_types: Map<string, number> = Helpers.getInternalDocumentTypesMap();

  show_delegate_task: boolean = false;
  delegate_task_async: boolean = false;
  delegate_task_message: string = "";

  searchedArchiveDocPermissionLevel: string;
  searchedCorresspondenceDocPermissionLevel: string;
  searchedDocPermLevel: string;

  sound = new Howl({
    src: ['assets/audio/noti.mp3'], html5: true
  });

  constructor(private network: NetworkService, private router: Router, private userIdle: UserIdleService, private dialog: MatDialog, private winRef: WindowRef, private cd: ChangeDetectorRef) {

    this.loggedUser = User.getCurrentUser();
    this.alertHelper = new AlertHelper(dialog);

  }
  TIME_OUT_SECONDS: number = 10;
  timeOutAlert: boolean = false;
  timeOutLeftCounter: number = 0;
  ngOnInit() {
    this.preloadData();
    this.sigStop = false;
    this.escapeListener();
  }

  initIdleListener(){
    //Start watching for user inactivity.
    this.userIdle.startWatching();
    // Start watching when user idle is starting.
    this.userIdle.onTimerStart().subscribe(count => {
      this.timeOutLeftCounter = this.TIME_OUT_SECONDS - count;
      this.cd.detectChanges();
      if(!this.timeOutAlert){
        this.timeOutAlert = true;
      }
    });

    // Start watch when time is up.
    this.userIdle.onTimeout().subscribe(() => {
      localStorage.setItem(Helpers.DS_INT_TIMEOUT, 'true');
      this.userIdle.stopWatching();
      this.logout();
    });
  }

  idleTimerStop(){
    this.userIdle.stopTimer();
    this.timeOutAlert = false;
  }

  stopWatchingAndLogOut(){
    this.userIdle.startWatching();
    this.logout();
  }

  playNotificationSound(){
    this.sound.play();
    Howler.volume(0.5);
  }


  initSignalR(){
    if(this.show_connection_retry){
      this.retry_connection_async = true;
      this.connection_text = "Retrying";
    }
    this.hubConnection = new HubConnectionBuilder()
        .withUrl(NetworkService.getSigInit(), { accessTokenFactory: () => this.loggedUser.token })
        .build();

    // this.hubConnection.serverTimeoutInMilliseconds = 300000;
    // this.hubConnection.keepAliveIntervalInMilliseconds = 150000;

    this.hubConnection.on("send", data => {
        console.log(data);
    });

    this.hubConnection.on(SREvent.RECEIVE_FS_NOTIFICATION, (data) =>{
      var noti: NotificationItem = JSON.parse(data);
      this.dashboard_vars.notifications.unshift(noti);
      if(!this.show_notification){
        this.dashboard_vars.notificationCount += 1;
      }
      this.loadNotifications();
      this.playNotificationSound();
    })

    this.hubConnection.on(SREvent.RECEIVE_CHAT_CREATED, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onMyChatCreated(data);
      }
    });
    this.hubConnection.on(SREvent.RECEIVE_CHAT_MESSAGE, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveChatMessage(data);
      }else{
        this.new_noti = true;
      }
      this.playNotificationSound();
    });
    this.hubConnection.on(SREvent.RECEIVE_GROUP_CHAT_MESSAGE, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveGroupChatMessage(data);
      }else{
        this.new_noti = true;
      }
      this.playNotificationSound();
    });
    this.hubConnection.on(SREvent.RECEIVE_GROUP_ADD, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveGroupAdd(data);
      }else{
        this.new_noti = true;
      }
      this.playNotificationSound();
    });
    this.hubConnection.on(SREvent.RECEIVE_GROUP_NAME_CHANGE, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveGroupNameChange(data);
      }
    });
    this.hubConnection.on(SREvent.RECEIVE_GROUP_TOGGLE_ADMIN, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveToggleGroupAdmin(data);
      }
    });
    this.hubConnection.on(SREvent.RECEIVE_GROUP_USER_EXIT, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveGroupUserExit(data);
      }
    });
    this.hubConnection.on(SREvent.RECEIVE_GROUP_ADD_USER, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveGroupAddUser(data);
      }
    });
    this.hubConnection.on(SREvent.RECEIVE_GROUP_REMOVE_USER, (data) =>{
      if(this.currentPage instanceof ChatpageComponent){
        this.currentPage.onReceiveGroupRemoveUser(data);
      }
    });

    this.hubConnection.on(SREvent.DASHBOARD_REFRESH, (data) =>{
      this.reloadDashBoardVars();
    });

    this.hubConnection.on(SREvent.FSCOMMENT_REFRESH, (data) =>{
      if(this.currentPage instanceof TreatFileComponent){
        this.currentPage.refreshComments();
      }
    });

    this.hubConnection.on(SREvent.TREAT_FILE_REFRESH, (data) =>{
      if(this.currentPage instanceof TreatFileComponent){
        this.currentPage.loadSubmissionDetails();
      }
    });

    this.hubConnection.on(SREvent.MEMO_REFRESH, (data) =>{
      if(this.currentPage instanceof MemosComponent){
        this.currentPage.laodMemos(true);
      }
    });

    this.hubConnection.start()
    .then(() => {
      this.retry_connection_async = false;
      this.show_connection_retry = false;
      this.setSignalRHUBConnection();
    }).catch(()=>{
      this.signalrRetrier();
    });

    this.hubConnection.onclose((e)=>{
      // console.log("clossed")
      // console.log(e);
      if(!this.sigStop){
        this.signalrRetrier();
      }
    })
  }

  signalrRetrier(){
    if(this.show_connection_retry){
      this.retry_connection_time += this.DEFAULT_RETRY_MILLIS;
    }else{
      this.retry_connection_time = this.DEFAULT_RETRY_MILLIS;
    }
    this.show_connection_retry = true;
    this.retry_connection_async = false;
    var retry_time_done = 0;
    this.connection_text = "Connection failed. Retrying in " + this.secsFormat(this.retry_connection_time);
    this.connection_interval_id = setInterval(() => {
      retry_time_done += 1000;
      var time_left = this.retry_connection_time - retry_time_done;
      this.connection_text = "Connection failed. Retrying in " + this.secsFormat(time_left);
      if(time_left <= 0){
        clearInterval(this.connection_interval_id);
        this.initSignalR();
      }
    }, 1000);
  }

  signalRRetry(){
    clearInterval(this.connection_interval_id);
    this.initSignalR();
  }

  secsFormat(milliseconds){
    var secs = milliseconds/1000;
    if(secs < 60){
      return "0:" + this.getSeconds(secs);
    }else{
      var mins = Math.floor(secs/60);
      var rem_secs = this.getSeconds(secs%60);
      return mins + ":" + rem_secs;
    }
  }

  getSeconds(secs: number){
    if(secs < 10){
      return "0" + secs;
    }
    return secs;
  }

  testSi(){
    this.hubConnection.invoke("TestComm");
  }

  openRewards(){
    this.router.navigate(['/rewards']);
  }

  loadNotifications(){
    var notifications: Array<NotificationItem> = this.dashboard_vars.notifications;
    notifications.sort((a,b)=>{
      return moment(a.time).diff(moment(b.time));
    })
    // for (let index = 0; index < 5; index++) {
    //   var notification = new NotificationItem();
    //   notification.title = "A notification" + index;
    //   notification.message = "This is a notification";
    //   notification.time = "2019-08-30T09:30:42-04:00";
    //   notification.type = NotificationType.FileSubmission;
    //   notification.extra = "#8568JNS";
    //   notifications.push(notification);
    // }
    this.notifications.today = notifications.filter((n) => {
      return this.isToday(n.time);
    }).reverse();
    this.notifications.older = notifications.filter((n) => {
      return !this.isToday(n.time);
    }).reverse();
  }

  isToday(date: string){
    var today = moment();
    var da = moment(moment(date).format("YYYY-MM-DD"));
    //console.log(today.diff(da, 'day'));
    return today.diff(da, 'days') <= 0;
  }

  retryPreload(){
    this.preload_err = false;
    this.preloadData();
  }

  modalShadow(){
    return false;
  }

  showNotifications(){
    this.show_notification = true;
    this.dashboard_vars.notificationCount = 0;
    this.parent.hideScroll();
  }

  hideScroll(){
    this.parent.hideScroll();
  }

  showScroll(){
    this.parent.showScroll();
  }

  hideNotifications(){
    this.show_notification = false;
    this.parent.showScroll();
  }

  viewNotification(noti: NotificationItem){
    if(noti.type == NotificationType.FileSubmission){
      if(noti.extra){
        if(noti.extra.indexOf('#') == 0){
          var sub_ref = noti.extra.replace("#", "");
          if(sub_ref){
            this.router.navigate(['/treat-file', sub_ref]);
          }
        }
      }
    }else if(noti.type == NotificationType.Memo){
      if(noti.extra){
        var extra = JSON.parse(noti.extra);
        if(extra['ref'] && !isNaN(parseInt(extra['type']))){
          var type = "";
          this.int_doc_types.forEach((v, k) => {
            if(v == extra['type']){
              type = k;
              return;
            }
          })
          this.router.navigate(['/document',type, extra['ref']]);
        }
      }
    }else if(noti.type == NotificationType.Archive){
      if(noti.extra){
        var sub_ref = noti.extra.replace("#", "");
        if(sub_ref){
          this.router.navigate(['/view-archive', sub_ref]);
        }
      }
    } else if(noti.type == NotificationType.Leave){
      if(noti.extra){
        var sub_ref = noti.extra.replace("#", "");
        if(sub_ref){
          this.router.navigate(['/view-leave', sub_ref]);
        }
      }
    }
    this.showScroll();
  }

  removeNotification(noti: NotificationItem){
    if(noti){
      var ids: Array<string> = new Array();
      ids.push(noti.id);
      noti.remove = true;
      setTimeout(() => {
        this.removeNotifications(ids);
      }, 500);
    }
  }

  removeNotifications(ids: Array<string>){
    if(ids.length > 0){
      this.dashboard_vars.notifications = this.dashboard_vars.notifications.filter((n) =>{
        return ids.indexOf(n.id) < 0;
      });
      this.loadNotifications();
      this.network.sendRequest({notificationIds: ids}, NetworkService.CLEAR_NOTIFICATION).subscribe((res)=>{
        if(!res['error']){
        }else{
        }
      }, (error)=>{
      })
    }
  }

  clearAll(time: number){
    var noti_ids: Array<string> = new Array();
    if(time == 0){
      //today
      if(this.notifications.today.length > 0){
        this.notifications.today.forEach((n) =>{
          noti_ids.push(n.id);
        })
      }
    }else if(time == 1){
      //older
      if(this.notifications.older.length > 0){
        this.notifications.older.forEach((n) =>{
          noti_ids.push(n.id);
        })
      }
    }
    this.removeNotifications(noti_ids);
  }

  getFullDate(dateString){
    return moment(dateString).format("MMMM Do YYYY, h:mm a")
  }
  fromNow(dateString){
    return moment(dateString).fromNow();
  }
  getShortDate(dateString){
    return moment(dateString).format("MMMM Do YYYY")
  }

  preloadData(){
    this.preloader = true;
    this.network.sendRequest({}, NetworkService.DASHBOARD).subscribe((res)=>{
      if(!res['error']){
        this.dashboard_vars = null;
        this.dashboard_vars = JSON.parse(res['response']);
        this.loadNotifications();
        this.setChildDashVars();
        this.initSignalR();
        this.initIdleListener();
        this.preloader = false;
        var mins = localStorage.getItem(Helpers.MIN_AG);
        var passwordStatus = JSON.parse(localStorage.getItem(Helpers.STORAGE_TAG));
        if(mins){
          var min_ag = JSON.parse(mins);
          if(min_ag.time){
            var stored_time = moment(min_ag.time)
            var now = moment();
            if(now.diff(stored_time, 'days') > 1){
              this.loadMinistries();
              if(passwordStatus.PasswordChanged === false) {
                this.alertHelper.showOkAlert('Info', 'This is your first login, please change your password');
                this.router.navigate(['/change-password']);
              }
            }else{
              localStorage.setItem(Helpers.MIN_AG, JSON.stringify(min_ag));
              if(!passwordStatus.PasswordChanged) {
                this.alertHelper.showOkAlert('Info', 'This is your first login, please change your password');
                this.router.navigate(['/change-password']);
              }
            }
          }else{
            this.loadMinistries();
            if(!passwordStatus.PasswordChanged) {
              this.alertHelper.showOkAlert('Info', 'This is your first login, please change your password');
              this.router.navigate(['/change-password']);
            }
          }
        }else{
          this.loadMinistries();
          if(!passwordStatus.PasswordChanged) {
            this.alertHelper.showOkAlert('Info', 'This is your first login, please change your password');
            this.router.navigate(['/change-password']);
          }
        }
      }else{
        this.preload_err = true;
      }
    }, (error)=>{
      this.preload_err = true;
    })
  }


  loadMinistries(){
    this.network.sendRequest({}, NetworkService.GET_MINISTRIES).subscribe((res)=>{
      if(!res['error']){
        // this.ministries = new Array();
        var _minis = JSON.parse(res['response']);
        // this.ministries = _minis.ministries_agencies;
        var min_ag = {time: moment(), data: _minis.ministries_agencies};
        localStorage.setItem(Helpers.MIN_AG, JSON.stringify(min_ag));
      }
    }, (error)=>{
    })
  }

  showPreloader(){
    this.preloader = true;
  }

  hidePreloader(){
    this.preloader = false;
  }

  toggleSideBar(){
    this.show_sidebar = !this.show_sidebar;
  }

  getBadgeColor(){
    return this.loggedUser.UserColour;
  }

  reloadDashBoardVars(){
    this.network.sendRequest({}, NetworkService.DASHBOARD, CacheRequest.Reload).subscribe((res)=>{
      if(!res['error']){
        this.dashboard_vars = JSON.parse(res['response']);
        this.setChildDashVars();
      }else{
      }
    }, (error)=>{
    })
  }

  setChildDashVars(){
    try {
      this.currentPage.setDashBoardVars(this.dashboard_vars);
    } catch (error) {
    }
  }

  showMemo(){
    JQTE.start();
    this.loadDepartments();
    this.memo_letter_minute = {show: true, async: false, name: "Memo", req: new MemoLetterMinuteRequest()};
  }
  showMinute(){
    JQTE.start();
    this.loadInstUsers();
    this.memo_letter_minute = {show: true, async: false, name: "Minute", req: new MemoLetterMinuteRequest()};
  }
  showCircular(){
    JQTE.start();
    this.loadDepartments();
    this.memo_letter_minute = {show: true, async: false, name: "Circular", req: new MemoLetterMinuteRequest()};
  }
  closeMemoLetterMinute(){
    this.memo_letter_minute = {show: false, async: false, name: "", req: new MemoLetterMinuteRequest()};
  }

  sendMemo(){
    var memo_err: boolean = false;
    var memo_err_msg: string = "";
    if(this.memo_letter_minute.name == 'Circular'){
      if(this.circular_type == 2){
        this.memo_letter_minute.req.ToDepartment = false
      }else{
        this.memo_letter_minute.req.ToDepartment = true
      }
    }
    this.memo_letter_minute.req.Message = JQTE.getValue('#job-req');
    if((this.memo_letter_minute.name == 'Memo' || this.memo_letter_minute.name == 'Circular') && this.selected_route_sub_user == null){
      memo_err = true;
      memo_err_msg = "Please select a user that will review this document";
    }else if(this.memo_letter_minute.name == 'Minute' && (this.selected_inst_users == null || this.selected_inst_users.length <= 0)){
      memo_err = true;
      memo_err_msg = "Please select atleast one user to send the minute to";
    }else if(this.memo_letter_minute.name == 'Circular' && this.circular_type == null){
      memo_err = true;
      memo_err_msg = "Please select a circular type";
    }else if(this.memo_letter_minute.req.Subject.trim() == ""){
      memo_err = true;
      memo_err_msg = "Please enter a subject";
    }else if(this.memo_letter_minute.req.Message.trim() == ""){
      memo_err = true;
      memo_err_msg = "Please enter a message";
    }
    if(!memo_err){
      var url = "";
      if(this.memo_letter_minute.name == "Memo"){
        url = NetworkService.CREATE_MEMO;
        this.memo_letter_minute.req.To = this.selected_route_sub_user.id;
      }else if(this.memo_letter_minute.name == "Minute"){
        url = NetworkService.CREATE_MINUTE;
        this.selected_inst_users.forEach(iu => {
          this.memo_letter_minute.req.MinuteTo.push(iu.id);
        })
      }else if(this.memo_letter_minute.name == "Circular"){
        url = NetworkService.CREATE_CIRCULAR;
        this.memo_letter_minute.req.To = this.selected_route_sub_user.id;
      }else{
        this.alertHelper.showErrorAlert();
        return;
      }
      this.memo_letter_minute.async = true;
      this.network.sendRequest(this.memo_letter_minute.req, url).subscribe((res) => {
        this.memo_letter_minute.async = false;
        if (!res['error']) {
          this.memo_letter_minute.show = false;
          if(this.currentPage instanceof MemosComponent && this.memo_letter_minute.name == "Memo"){
            this.currentPage.memos.push(JSON.parse(res['response']))
          }
          this.alertHelper.showOkAlert("Success", "Successfully created " + this.memo_letter_minute.name +".");
        }else{
          this.memo_letter_minute.async = false;
          this.alertHelper.showOkAlert("Error", res['response']);
        }
      }, (error) => {
        this.memo_letter_minute.async = false;
        this.alertHelper.showErrorAlert();
      })
    }else{
      this.alertHelper.showOkAlert("Error", memo_err_msg);
    }

  }

  closeSideBar(){
    this.show_sidebar = false;
  }

  getInitials(){
   return this.loggedUser.FirstName.charAt(0).toUpperCase() + this.loggedUser.LastName.charAt(0).toUpperCase();
  }

  eventCalled(event) {
    this.currentPage = event;
    this.currentPageName = event.pageName;
    event.parent = this;
    this.setChildDashVars();
    this.setSignalRHUBConnection();
    if(this.currentPage instanceof ChatpageComponent){
      this.new_noti = false;
    }
  }

  confirmLogout() {
    this.alertHelper.showYesNoAlert("Logout?", "Are you sure you want to logout?", () => { this.logout(); });
  }

  sigStop: boolean = false;
  logout() {
    this.sigStop = true;
    this.hubConnection.stop().then(() =>{
    })
    localStorage.removeItem(Helpers.STORAGE_TAG);
    this.router.navigate(['/login'])
  }

  setSignalRHUBConnection(){
    try {
      this.currentPage.setHubConnection(this.hubConnection);
    } catch (error) {
    }
  }

  loadInstUsers(){
    this.selected_inst_users = [];
    if(this.inst_users.length <= 0){
      this.inst_users_load_async = true;
      this.network.sendRequest({}, NetworkService.GET_INST_USERS).subscribe((res)=>{
        this.inst_users_load_async = false
        if(!res['error']){
          var usrs = JSON.parse(res['response']);
          this.inst_users = usrs;
        }
      }, (error)=>{
        this.inst_users_load_async = false;
      })
    }
  }

  loadDepartments(){
    this.refreshDepartmentUsers();
    var _team = "0"
    if(this.submission_departments.length <= 0){
      this.departments_load_async = true;
      this.network.sendRequest({}, NetworkService.GET_DEPARTMENTS).subscribe((res)=>{
        this.departments_load_async = false
        if(!res['error']){
          var deps = JSON.parse(res['response']);
          this.submission_departments = deps;

        }
      }, (error)=>{
        this.departments_load_async = false;
      })
    }
  }

  departmentChange(event){
    this.refreshDepartmentUsers();
    this.sel_department = event.value;
    if(event.value){
      this.department_users_load_async = true;
      this.network.sendRequest({did: event.value.id}, NetworkService.GET_DEPARTMENT_USERS_PLAIN).subscribe((res) => {
        this.department_users_load_async = false
        if(!res['error']){
          var usrs = JSON.parse(res['response']);
          this.department_users = usrs;
        }
      }, (error) => {
        this.department_users_load_async = false
      });
    }
  }

  refreshDepartmentUsers(){
    this.toggle_users_dropdown = false;
    this.selected_route_sub_user = null;
    this.department_users = new Array();
    setTimeout(() => {
      this.toggle_users_dropdown = true;
    }, 1);
  }

  escapeListener(){
    var __this = this;
    setTimeout(() => {
      $(document).on('keyup', function(e){
        if(e.keyCode == 27){
          if(__this.memo_letter_minute.show){
            __this.closeMemoLetterMinute();
          }else if(__this.currentPage instanceof DashboardComponent){
            if(__this.currentPage.file_stats_show){
              __this.currentPage.closeFileStats();
            }else if(__this.currentPage.view_file_catch_files){
              __this.currentPage.closeViewFileCatchFiles();
            }else if(__this.currentPage.show_file_catch){
              __this.currentPage.show_file_catch = false;
            }
          }else if(__this.currentPage instanceof ChatpageComponent){
            if(__this.currentPage.add_user){
              __this.currentPage.add_user = false;
            }
          }
        }
      })
    }, 500);
  }
  showScanner: boolean = false;
  scanDocument(){

    this.winRef.nativeWindow.AcquireImage();
  }

  showDelegateTask(){
    this.loadInstUsers();
    this.show_delegate_task = true;
    this.delegate_task_message = "";
    this.selected_delegate_files = [];
  }
  closeDelegateTask(){
    this.show_delegate_task = false;
    this.delegate_task_message = "";
    this.delegate_task_async = false;
  }

  selected_delegate_task_user: any;
  selected_delegate_files: Array<File> = new Array();

  sendDelegateTask(){
    if(!this.selected_delegate_task_user){
      this.alertHelper.showOkAlert("Error", "Please select a user.");
    }else if(this.delegate_task_message.trim() == ""){
      this.alertHelper.showOkAlert("Error", "Please enter a message for the user.")
    }else if(this.delegate_task_message.trim().length < 2){
      return;
    }else{
      this.delegate_task_async = true;
      var data = {TaskRecipientId: this.selected_delegate_task_user.id, SendMessage: this.delegate_task_message};
      if(this.selected_delegate_files.length > 0){
        this.network.filesUploadKey(this.selected_delegate_files, NetworkService.DELEGATE_TASK, data, "AttachedFiles").subscribe((res)=>{
          this.handleDelegateFileUpload(res);
        })
      }else{
        this.network.sendRequest(data, NetworkService.DELEGATE_TASK).subscribe((res)=>{
          this.delegate_task_async = false;
          if(!res['error']){
            this.show_delegate_task = false;
            this.alertHelper.showOkAlert("Success", res['response']);
          }else{
            this.alertHelper.showOkAlert("Error", res['response']);
          }
        }, (error)=>{
          this.delegate_task_async = false;
          this.alertHelper.showErrorAlert();
        })
      }

    }
  }

  delegateFileMessage(event){
    this.alertHelper.showOkAlert(event.title, event.message);
  }

  handleDelegateFileUpload(event){
    if (event.type === HttpEventType.Response) {
      var serverResponse = event.body
      this.delegate_task_async = false;
      if(!serverResponse['error']){
        this.show_delegate_task = false;
        this.selected_delegate_files = [];
        this.alertHelper.showOkAlert("Success", serverResponse['response'])
      }else{
        this.alertHelper.showOkAlert("Error", serverResponse['response'])
      }
    }
  }
}

export class DashboardValues{
  untreated_files = {count: 0, today: 0};
  awaiting_files = {count: 0, today: 0};
  treated_files = {count: 0, today: 0};
  pending_files = {count: 0, today: 0};
  memos = {count: 0, today: 0};
  circulars = {count: 0, today: 0};
  minutes = {count: 0, today: 0};
  tasks = {count: 0, today: 0};
  notifications: Array<NotificationItem> = new Array();
  notificationCount: number = 0;
  user_points: number = 0;
  treatment_history: Object[] = [];
  points_left_to_badge: number = 0;
  points_left_to_trophy: number = 0;
  points_to_get_badge: number = 0;
  permissionLevel: number;
  overdelayed: number = 0;
}

export class MemoLetterMinuteRequest{
  Title: string = "";
  To: string = "";
  Message: string = "";
  Subject: string = "";
  ToDepartment: boolean = true;
  MinuteTo: string[] = [];
}
export class NotificationItem{
  id: string = "";
  title: string = "";
  message: string = "";
  time: string = "";
  type: NotificationType = NotificationType.FileSubmission;
  extra: string;
  remove: boolean = false;
}


enum NotificationType{
  FileSubmission = 1,
  Chat = 2,
  Task = 3,
  Memo = 4,
  MemoRoute = 5,
  Archive = 8,
  Leave = 10,
}
